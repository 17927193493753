import { loadStatusPageDataSuccess } from "./actions";
import camelcaseKeys from "camelcase-keys";

// This is the only function we need to inject our payload now because
// we fetch ising the payloadContextProvider because we have to fetch
// to initialize the app
export const initializePayload = (payload) => {
  return (dispatch) =>
    dispatch(
      loadStatusPageDataSuccess(
        camelcaseKeys(payload, {
          deep: true,
          stopPaths: ["conditions", "incidents.componentConditions"],
        })
      )
    );
};
