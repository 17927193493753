import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import CurrentStatus, { CurrentMaintenanceStatus, UpcomingMaintenanceStatus } from './CurrentStatus';
import ComponentConditionCollection from './ComponentConditionCollection';
import IncidentHistory from '../shared/IncidentHistory';
import { useSelector } from 'react-redux';
import StatusKey from './StatusKey';

const HomeView = () => {
  const config = useSelector(state => state.statusPage.config);

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  };

  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);

    }, []);

    return windowDimensions;
  };

  const { width } = useWindowDimensions();

  return (
    <>
      {Object.keys(config).length > 0 && (

        <Helmet>
          <meta charSet="utf-8" />
          <title>{config.companyName} Status</title>
        </Helmet>
      )}
      <CurrentStatus />
      <CurrentMaintenanceStatus />
      <UpcomingMaintenanceStatus />
      <div className="row">
        <div className="col-12 col-md-12 col-lg-9">
          { width <= 768 && <div className="mx-4 my-7"><StatusKey /></div>}
          <ComponentConditionCollection />
          <IncidentHistory />
        </div>
        <div className="col-12 col-md-4 col-lg-3 mt-6">
          { width > 768 && <StatusKey /> }
        </div>
      </div>
    </>
  );
};

export default HomeView;