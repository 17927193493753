import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React from "react";

const API_KEY = "50f1b71460580af8963f749ae93d2c1d";

let ErrorBoundary;
let env = null;

// TODO:
// This is a hack and we should implement it with env variables on deployments
if (
  window?.location?.hostname?.indexOf("staging.firehydrant.io") > -1 ||
  window?.location?.hostname?.indexOf("boblad.com") > -1
) {
  env = "staging";
} else if (
  window?.location?.hostname?.indexOf("status.local.firehydrant.io") === -1 &&
  window?.location?.hostname?.indexOf("localhost") === -1
) {
  env = "production";
}

if (env) {
  Bugsnag.start({
    releaseStage: env,
    apiKey: API_KEY,
    plugins: [new BugsnagPluginReact()],
  });

  ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
} else {
  ErrorBoundary = ({ children }) => <>{children}</>;
}

export default ErrorBoundary;
