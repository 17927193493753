
export const initialLoadSelector = (state) => state.statusPage.statusPageData.initial;
export const loadErrorSelector = (state) => state.statusPage.statusPageDataLoadError;
export const incidentsSelector = (state) => state.statusPage.allIncidents;
export const statusPageDataPendingSelector = (state) => state.statusPage.statusPageDataPending;
export const configSelector = (state) => state.statusPage.config;

// This "selector" actually returns a selector given some args.
// NB: For posterity -I'm not sure this is a great pattern in general. Let's say the selector is going to memoize.
//     Since the selector gets created every time, we need a way to store the memoized data apart from
//     the creation of the selector function. In that case it would be better to use a reducer to do 
//     the expensive work.
export const getIncidentByIdSelector = (id) => ((state) => state.statusPage.allIncidents.find(item => (item.id === id)));