import React from "react";
import { Provider } from "react-redux";
import store from "../store";
import "../index.css";
import "./shared/Markdown/markdown.scss";
import NuncV1 from "./NuncV1";
import NuncV2 from "../v2/NuncV2";
import DarkContextProvider from "./DarkContextProvider";
import PayloadContextProvider from "./PayloadContextProvider";
import { usePayloadContext } from "./usePayloadContext";
import ErrorBoundary from "./shared/ErrorBoundary";

const Wrapper = ({ children }) => (
  <div className="flex h-[100vh] justify-center items-center bg-fh-gray-dark-2">
    <h2 className="font-semibold text-xl text-white">{children}</h2>
  </div>
);

const Nunc = () => {
  const { config, isLoaded, error, rawPayload } = usePayloadContext();
  if (error) {
    return <Wrapper>An error occurred.</Wrapper>;
  }
  if (!isLoaded) {
    return <Wrapper>Loading...</Wrapper>;
  }

  // uiVersion is configured in the admin. only 2 options can happen today
  // 1 is off 2 is on for the new UI. If we need later versions we can allow
  // option 3...4...5
  return (
    <>
      {config?.uiVersion === 2 ? (
        <DarkContextProvider>
          <NuncV2 />
        </DarkContextProvider>
      ) : (
        <Provider store={store}>
          <NuncV1 payload={rawPayload} />
        </Provider>
      )}
    </>
  );
};

const App = () => (
  <ErrorBoundary>
    <PayloadContextProvider>
      <Nunc />
    </PayloadContextProvider>
  </ErrorBoundary>
);

export default App;
