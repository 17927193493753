import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import ConditionLegend from "./common/ConditionLegend";
import ComponentHealthGraph from "./ComponentHealthGraph";
import { usePayloadContext } from "components/usePayloadContext";

const flattenGroup = (group, components = []) => {
  if (group.components) {
    group.components.forEach((component) => {
      // if is a group
      if (component.components) {
        flattenGroup(component, components);
      } else {
        components.push(component);
      }
    });
  }
  return components;
};

const GroupedHealthSection = ({
  components,
  depth = 0,
  showBreadcrumbs,
  parentGroupNames = [],
  ...props
}) => {
  const [collapseOpenList, setCollapseOpenList] = useState([]);
  const pl = Math.max(depth, 1) * 16;
  return components?.map((componentOrGroup, index) => {
    const isGroup = componentOrGroup?.components;
    const isOpen = collapseOpenList?.indexOf(componentOrGroup.id) > -1;
    return (
      <div
        key={`${componentOrGroup.name}-${index}`}
        className={`${
          !depth
            ? "bg-white dark:bg-fh-gray-dark-3 border rounded border-fh-gray-6 dark:border-fh-gray-dark-6 mb-4 p-4"
            : ""
        } flex flex-col grid gap-y-4`}
        style={{ paddingLeft: showBreadcrumbs ? undefined : `${pl}px` }}
      >
        {(!showBreadcrumbs || !isGroup) && (
          <ComponentHealthGraph
            onCollapseToggle={() => {
              if (isOpen) {
                setCollapseOpenList(
                  collapseOpenList.filter((id) => id !== componentOrGroup.id)
                );
              } else {
                setCollapseOpenList([...collapseOpenList, componentOrGroup.id]);
              }
            }}
            parentGroupNames={
              showBreadcrumbs ? parentGroupNames.join(" > ") : []
            }
            name={componentOrGroup?.name}
            isGroup={Boolean(isGroup)}
            collapseIsOpen={isOpen}
            components={
              isGroup ? flattenGroup(componentOrGroup) : [componentOrGroup]
            }
            {...props}
          />
        )}
        {isGroup && (isOpen || showBreadcrumbs) && (
          <GroupedHealthSection
            components={componentOrGroup.components}
            parentGroupNames={[...parentGroupNames, componentOrGroup.name]}
            showBreadcrumbs={showBreadcrumbs}
            depth={depth + 1}
            {...props}
          />
        )}
      </div>
    );
  });
};

const buildBranch = (parentGroup, groups, components) => {
  parentGroup.components = [];
  groups.forEach((group) => {
    if (group.componentGroupId === parentGroup.id) {
      const branch = buildBranch(group, groups, components);
      parentGroup.components.push(branch);
    }
  });

  components.forEach((component) => {
    if (component.componentGroupId === parentGroup.id) {
      parentGroup.components.push(component);
    }
  });
  return parentGroup;
};

const buildComponentGroupTree = (
  groupsOriginal = [],
  componentsOriginal = []
) => {
  const groups = groupsOriginal?.map((group) => ({
    componentGroupId: group?.component_group_id,
    ...group,
  }));
  const components = JSON.parse(JSON.stringify(componentsOriginal));
  let tree = [];
  groups.forEach((group) => {
    if (!group.componentGroupId) {
      group = buildBranch(group, groups, components);
      tree.push(group);
    }
  });
  components.forEach((component) => {
    if (!component.componentGroupId) {
      tree.push(component);
    }
  });
  return tree;
};

// We use this ComponentHealthSection component with the showBreadcrumbs prop rather than
// just the ComponentHealthGraph componet because we dont want to
// reconstruct incidentsByComponent variable.
const ComponentHealthSection = ({
  components,
  componentGroups,
  showBreadcrumbs,
}) => {
  const all = usePayloadContext();
  const { incidentMap, conditions, allIncidents } = all;

  const componentsTree = useMemo(() => {
    return buildComponentGroupTree(componentGroups, components);
  }, [components, componentGroups, allIncidents]);

  const incidentsByComponent = useMemo(() => {
    return allIncidents?.reduce((acc, incident) => {
      Object.keys(incident.componentConditions || {})?.forEach((condition) => {
        if (condition in acc) {
          acc[condition].push(incident);
        } else {
          acc[condition] = [incident];
        }
      });
      return acc;
    }, {});
  }, [allIncidents]);

  if (!components?.length) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center justify-between mb-4">
        <h3 className="text-xl">Affected Components</h3>
        <ConditionLegend />
      </div>

      <GroupedHealthSection
        components={componentsTree}
        incidentMap={incidentMap}
        conditions={conditions}
        incidentsByComponent={incidentsByComponent}
        showBreadcrumbs={showBreadcrumbs}
      />
    </div>
  );
};

ComponentHealthSection.propTypes = {
  showBreadcrumbs: PropTypes.bool,
  components: PropTypes.arrayOf(PropTypes.object),
  componentGroups: PropTypes.arrayOf(PropTypes.object),
};

export default ComponentHealthSection;
