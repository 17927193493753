import React from "react";
import ComponentHealthSection from "v2/components/ComponentHealthSection";
import { usePayloadContext } from "components/usePayloadContext";

// Crawl up the component group tree to find all groups that are parents of a component
const getAllGroupsWithComponent = (component, groupDict = {}) => {
  const foundGroups = [];
  let parentGroup = groupDict[component.componentGroupId];
  while (parentGroup) {
    foundGroups.push(parentGroup);
    parentGroup = groupDict[parentGroup.componentGroupId];
  }
  return foundGroups;
};

// retrurn component groups that are used by a component
const getFilteredComponentGroups = (
  componentGroups = [],
  filteredComponents = []
) => {
  const foundGroupSet = new Set();
  const groupDict = componentGroups.reduce((acc, group) => {
    acc[group.id] = group;
    return acc;
  }, {});
  filteredComponents.forEach((component) => {
    const groups = getAllGroupsWithComponent(component, groupDict);
    groups.forEach((group) => {
      foundGroupSet.add(group.id);
    });
  });
  return Array.from(foundGroupSet)?.map((id) => groupDict[id]);
};

const IncidentAffectedComponents = ({ incident }) => {
  const { components, componentGroups } = usePayloadContext();
  const componentNames = Object.keys(incident?.componentConditions || {});
  // Components that are affected by this incident but we need the ones with ids
  const filteredComponents = components?.filter(
    (component) => componentNames.indexOf(component.name) > -1
  );

  const filteredComponenntGroups = getFilteredComponentGroups(
    componentGroups,
    filteredComponents
  );
  if (!filteredComponents?.length) return null;
  return (
    <ComponentHealthSection
      components={filteredComponents}
      componentGroups={filteredComponenntGroups}
      showBreadcrumbs
    />
  );
};

export default IncidentAffectedComponents;
