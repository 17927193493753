export const LOAD_STATUS_PAGE_DATA_SUCCESS = "LOAD_STATUS_PAGE_DATA_SUCCESS";
export function loadStatusPageDataSuccess(data) {
  return {
    type: LOAD_STATUS_PAGE_DATA_SUCCESS,
    data: data,
  };
}

export const EMAIL_SUBSCRIPTION_REQUEST = "EMAIL_SUBSCRIPTION_REQUEST";
export const emailSubscriptionRequest = (data) => {
  return {
    type: EMAIL_SUBSCRIPTION_REQUEST,
    data: data,
  };
};

export const EMAIL_SUBSCRIPTION_SUCCESS = "EMAIL_SUBSCRIPTION_SUCCESS";
export const emailSubscriptionSuccess = (data) => {
  return {
    type: EMAIL_SUBSCRIPTION_SUCCESS,
    data: data,
  };
};

export const EMAIL_SUBSCRIPTION_ERROR = "EMAIL_SUBSCRIPTION_ERROR";
export const emailSubscriptionError = (error) => {
  return {
    type: EMAIL_SUBSCRIPTION_ERROR,
    error: error,
  };
};

export const EMAIL_UNSUBSCRIBE_REQUEST = "EMAIL_UNSUBSCRIBE_REQUEST";
export const emailUnsubscribeRequest = (data) => {
  return {
    type: EMAIL_UNSUBSCRIBE_REQUEST,
    data: data,
  };
};

export const EMAIL_UNSUBSCRIBE_SUCCESS = "EMAIL_UNSUBSCRIBE_SUCCESS";
export const emailUnsubscribeSuccess = (data) => {
  return {
    type: EMAIL_UNSUBSCRIBE_SUCCESS,
    data: data,
  };
};

export const EMAIL_UNSUBSCRIBE_ERROR = "EMAIL_UNSUBSCRIBE_ERROR";
export const emailUnsubscribeError = (error) => {
  return {
    type: EMAIL_UNSUBSCRIBE_ERROR,
    error: error,
  };
};

export const SET_GLOBAL_ALERT = "SET_GLOBAL_ALERT";
export const setGlobalAlert = (alert) => {
  return {
    type: SET_GLOBAL_ALERT,
    alert: alert, // Should be an object { level: info|warning|ect, message: "..." }
  };
};

export const DISMISS_GLOBAL_ALERT = "DISMISS_GLOBAL_ALERT";
export const dismissGlobalAlert = (alert) => {
  return {
    type: DISMISS_GLOBAL_ALERT,
    alert: alert,
  };
};
