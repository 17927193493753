import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  conditionIcons,
  getMostSevereComponent,
  DegradedCondition,
} from "../../helpers/conditionHelpers";
import { useSelector } from "react-redux";
import { relativeIncidentUrl } from "../../helpers/urls";

export const IncidentHistorySummary = ({
  incident,
  showBorderBottom = true,
}) => {
  const conditionMap = useSelector((state) => state.statusPage.conditionMap);
  const component = getMostSevereComponent(
    incident.componentConditions,
    conditionMap
  );
  const affectedComponents = Object.keys(incident.componentConditions || {});
  const hasAffectedComponents =
    Object.keys(incident.componentConditions || {}).length > 0;
  const renderAffectedComponents = () => (
    <p className="text-sm mb-0">
      <strong>
        Affected component{affectedComponents.length > 1 ? "s" : ""}:{" "}
      </strong>
      {affectedComponents.map((c) => `${c}`).join(", ")}
    </p>
  );

  const icon =
    component && Object.keys(component).length > 0
      ? conditionIcons[
          conditionMap[component.customerCondition] || DegradedCondition
        ]
      : conditionIcons[DegradedCondition];
  return (
    <>
      <Link
        to={relativeIncidentUrl(incident)}
        className="incident-summary row pb-0 mb-0"
        key={`incident-summary-${incident.id}`}
      >
        <div className="col-10 pr-0">
          <p className="m-0">
            <em className="text-sm">
              Created on{" "}
              {moment(incident.timestamps.started).format("MMMM DD, YYYY")}
            </em>
          </p>
          <h3 className="my-1">{incident.title}</h3>
          {hasAffectedComponents && renderAffectedComponents()}
        </div>
        <div className="col-2 align-self-center">
          {icon({ className: "ml-auto my-auto" })}
        </div>
      </Link>
      {showBorderBottom && <hr className="my-3" />}
    </>
  );
};

IncidentHistorySummary.propTypes = {
  incident: PropTypes.shape(),
  showTitle: PropTypes.bool,
  severity: PropTypes.string,
};

export default IncidentHistorySummary;
