import React, { useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";

const Tooltip = ({ children, className = "", tooltipContent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const { styles, attributes } = usePopper(referenceElement, popperElement);

  return (
    <Popover className="relative">
      <div onMouseLeave={() => setIsOpen(false)}>
        <Popover.Button
          ref={setReferenceElement}
          className={className}
          onMouseEnter={() => {
            setIsOpen(true);
          }}
        >
          {children}
        </Popover.Button>
        <Transition as={React.Fragment} show={isOpen}>
          <Popover.Panel
            className="absolute z-10"
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
          >
            <div
              role="tooltip"
              className="z-10 px-2 py-2.5 text-sm font-medium bg-white border-fh-gray-light-3 rounded-lg shadow-sm tooltip dark:bg-fh-gray-dark-1 min-w-[200px] border dark:border-fh-gray-dark-6 dark:text-fh-gray-6"
            >
              {tooltipContent}
            </div>
          </Popover.Panel>
        </Transition>
      </div>
    </Popover>
  );
};

export default Tooltip;
