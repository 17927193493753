import { EMAIL_SUBSCRIPTION_REQUEST, EMAIL_SUBSCRIPTION_SUCCESS, EMAIL_SUBSCRIPTION_ERROR } from '../actions/actions';

const initialState = {
  subscriptionRequestPending: false,
  subscriptionRequestSuccess: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case EMAIL_SUBSCRIPTION_REQUEST: {
      return { ...state, subscriptionRequestPending: true };
    }
    case EMAIL_SUBSCRIPTION_SUCCESS: {
      global.dispatchEvent(new CustomEvent('subscriptionSuccess', { detail: action.data }));
      return { ...state, subscriptionRequestPending: false, subscriptionRequestError: null, subscriptionRequestSuccess: true, };
    }
    case EMAIL_SUBSCRIPTION_ERROR: {
      return { ...state, subscriptionRequestPending: false, subscriptionRequestError: action.error, subscriptionRequestSuccess: false };
    }
    default:
      return state;
  }
};
