import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CUSTOMER_LOGO_URL } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import Header from "./shared/header/Header";
import HomeView from "./home/HomeView";
import Footer from "./shared/Footer";
import { initializePayload } from "../actions/loadStatusPageData";
import IncidentView from "./incident/IncidentView";
import HistoryView from "./history/HistoryView";
import UnsubscribeView from "./unsubscribe/UnsubscribeView";
import GlobalAlerts from "./shared/GlobalAlerts";
import { dismissGlobalAlert } from "../actions/actions";
import { initialLoadSelector } from "../helpers/stateSelectors";
import LoadingMessage from "./shared/LoadingMessage";
import "../scss/App.scss";

const apiURL = process.env.REACT_APP_API_URL;
const logoURL = apiURL ? `${apiURL}${CUSTOMER_LOGO_URL}` : CUSTOMER_LOGO_URL;

const NuncV1 = ({ payload }) => {
  const dispatch = useDispatch();
  const globalAlerts = useSelector((state) => state.app.alerts);
  useEffect(() => {
    if (payload) {
      dispatch(initializePayload(payload));
    }
  }, [payload]);

  const isInitialLoad = useSelector(initialLoadSelector);

  const appContent = (
    <Routes>
      <Route path="/" element={<HomeView />} />
      <Route path="/incidents/:incidentId" element={<IncidentView />} />
      <Route path="/history" element={<HistoryView />} />
      <Route path="/unsubscribe/:token" element={<UnsubscribeView />} />
    </Routes>
  );

  return (
    <div className="nunc-v1" id="nunc-v1-root">
      <Router>
        <Header logoUrl={logoURL} />
        <div className="container">
          <GlobalAlerts
            alerts={globalAlerts}
            onDismiss={(alert) => dispatch(dismissGlobalAlert(alert))}
          />
          {isInitialLoad ? <LoadingMessage /> : appContent}
          <Footer />
        </div>
      </Router>
    </div>
  );
};

export default NuncV1;
