import React, {createContext, useEffect, useState} from "react";

export const DarkContext = createContext();

const DarkContextProvider = ({ children }) => {
  const [isDark, setIsDark] = useState(false);
  useEffect(() => {
    const isSystemDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    if (isSystemDarkMode && localStorage.getItem("isDarkMode") === null) {
      localStorage.setItem("isDarkMode", "true");
    }
    localStorage.getItem("isDarkMode") === "true"
      ? setIsDark(true)
      : setIsDark(false);
  }, []);
  return (
    <DarkContext.Provider value={{ isDark, setIsDark }}>
      {children}
    </DarkContext.Provider>
  );
}

export default DarkContextProvider;