import {
  DEGRADED,
  OPERATIONAL,
  OFFLINE,
  SCHEDULED_MAINTENANCE,
} from "v2/constants/conditions";

export const getIncidentCondition = (incident, conditions) => {
  if (incident?.timestamps?.resolved) {
    return OPERATIONAL;
  }

  if (incident?.severitySlug === "MAINTENANCE") {
    return SCHEDULED_MAINTENANCE;
  }

  const hasOffline =
    Object.keys(incident?.componentConditions || {}).findIndex((component) => {
      return conditions[incident?.componentConditions?.[component]] === OFFLINE;
    }) > -1;
  if (hasOffline) {
    return OFFLINE;
  }

  return DEGRADED;
};
