import React, { useState } from "react";
import {
  DEGRADED,
  OFFLINE,
  OPERATIONAL,
  SCHEDULED_MAINTENANCE,
} from "../constants/conditions";
import { statusColors } from "v2/constants/colors";
import { plural } from "helpers/language";
import HistogramBar from "./HistogramBar";
import dayjs from "dayjs";
import { DownIcon, UpIcon, StatusIcons } from "./Icons";
import { usePayloadContext } from "components/usePayloadContext";

// return the most relevant condition for a group of components
const getComponentCondition = (
  incidentsByComponent,
  components,
  conditions,
  scheduledMaintenances
) => {
  const conditionOptions = new Set();
  // For incidents that have as least one of the components add the condition
  // to the condition options if the incident is not resolved
  components.forEach((component) => {
    incidentsByComponent?.[component?.name]?.forEach((incident) => {
      const condition =
        conditions[incident?.componentConditions?.[component.name]];
      if (condition && !incident?.timestamps?.resolved) {
        conditionOptions.add(condition);
      }
    });
  });

  if (conditionOptions.has(OFFLINE)) {
    return OFFLINE;
  }
  if (conditionOptions.has(DEGRADED)) {
    return DEGRADED;
  }

  // set to maintenance if there is a scheduled maintenance
  const now = dayjs();
  const activeComponentScheduledMaintenance = scheduledMaintenances?.find(
    (maintenance) =>
      Object.keys(maintenance?.componentConditions || {})?.find(
        (maintenanceComponentKey) =>
          now > dayjs(maintenance.startsAt) &&
          now < dayjs(maintenance.endsAt) &&
          components.findIndex(
            (component) => maintenanceComponentKey === component?.name
          ) > -1
      )
  );
  if (activeComponentScheduledMaintenance) {
    return SCHEDULED_MAINTENANCE;
  }

  return OPERATIONAL;
};

const dayCount = 90;

const ComponentHealthGraph = ({
  incidentMap,
  isGroup,
  collapseIsOpen,
  onCollapseToggle,
  components,
  name,
  parentGroupNames,
  incidentsByComponent,
}) => {
  const all = usePayloadContext();
  const { conditions, scheduledMaintenances, config } = all;
  const condition = getComponentCondition(
    incidentsByComponent,
    components,
    conditions,
    scheduledMaintenances
  );
  const startDate = useState(
    dayjs().subtract(90, "day").format("MMMM D, YYYY")
  )[0];
  const [pillId, setPillId] = useState(null);
  const StatusIcon = StatusIcons[condition];
  const statusColor = statusColors[condition];
  return (
    <div>
      <div className="flex items-center">
        <div className="mr-2">
          <StatusIcon
            className={`text-fh-${statusColor} ${
              isGroup ? "w-[20px] h-[20px]" : "w-[16px] h-[16px]"
            }`}
          />
        </div>
        <h3>{name}</h3>
        {parentGroupNames && (
          <p className="ml-2 text-fh-gray-light-11 dark:text-fh-gray-dark-11 md:text-sm text-xs w-max">
            {parentGroupNames}
          </p>
        )}
        {isGroup && components?.length > 0 && (
          <button className="flex items-center ml-2" onClick={onCollapseToggle}>
            <span className="text-fh-gray-light-11 dark:text-fh-gray-dark-11 md:text-sm text-xs w-max">{`${
              components.length
            } component${plural(components.length, "", "s")}`}</span>
            <span className="ml-2">
              {collapseIsOpen ? <UpIcon /> : <DownIcon />}
            </span>
          </button>
        )}
      </div>
      {config?.enableHistogram && (!isGroup || !collapseIsOpen) && (
        <>
          <div className="aspect-auto flex gap-0.5 my-2">
            {Array.from(Array(dayCount).keys())?.map((_, i) => {
              const dayInTime = dayjs().subtract(dayCount - i - 1, "day");
              const year = dayInTime.year();
              const month = dayInTime.month();
              const day = dayInTime.date();
              const incidents = incidentMap?.[year]?.[month]?.[day]?.filter(
                (incident) =>
                  incident?.severitySlug !== "MAINTENANCE" &&
                  incident.componentConditions &&
                  components.findIndex(
                    (component) =>
                      component.name in incident.componentConditions
                  ) > -1
              );
              const hasOutage = incidents?.some(
                (incident) =>
                  Object.keys(incident?.componentConditions || {})?.filter(
                    (key) =>
                      conditions[incident.componentConditions[key]] === OFFLINE
                  ).length > 0
              );

              const currentCondition = hasOutage
                ? OFFLINE
                : incidents?.length > 0
                ? DEGRADED
                : OPERATIONAL;
              const tooltipName = `${name}-${i}-tooltip`;
              const isHoverActive = pillId === tooltipName;
              return (
                <HistogramBar
                  key={tooltipName}
                  tooltipName={tooltipName}
                  isHoverActive={isHoverActive}
                  setPillId={setPillId}
                  dayInTime={dayInTime}
                  conditions={conditions}
                  currentCondition={currentCondition}
                  incidents={incidents}
                />
              );
            })}
          </div>
          <div className="flex justify-between text-xs text-fh-gray-light-11 text-fh-gray-dark-11">
            <p>{startDate}</p>
            <p>Today</p>
          </div>
        </>
      )}
    </div>
  );
};

export default ComponentHealthGraph;
