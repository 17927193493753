import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationTriangle, faWrench, faMinusCircle, faBell } from '@fortawesome/free-solid-svg-icons';

const sizeToFASize = {
  sm: 'xs',
  md: 'md',
  lg: 'lg',
  xl: '3x'
};

export const OperationalIcon = ({ size = 'sm', className }) => {
  return (
    <div className={`oval-${size} bg-success d-flex ${className}`}><FontAwesomeIcon icon={faCheck} color="#fff" size={sizeToFASize[size]} className="m-auto" /></div>
  );
};

export const DegradedIcon = ({ size = 'sm', className }) => {
  const topPadding = {
    sm: 'my-auto',
    md: 'my-auto',
    lg: 'mt-3',
    xl: 'mt-4'
  };
  return (
    <div className={`oval-${size} bg-warning d-flex ${className}`}><FontAwesomeIcon icon={faExclamationTriangle} color="#fff" size={sizeToFASize[size]} className={`mx-auto ${topPadding[size]}`} /></div>
  );
};

export const MaintenanceIcon = ({ size = 'sm', className }) => {
  return (
    <div className={`oval-${size} bg-info d-flex ${className}`}><FontAwesomeIcon icon={faWrench} color="#fff" size={sizeToFASize[size]} className="m-auto" /></div>
  );
};

export const OutageIcon = ({ size = 'sm', className }) => {
  return (
    <div className={`oval-${size} bg-danger d-flex ${className}`}><FontAwesomeIcon icon={faMinusCircle} color="#fff" size={sizeToFASize[size]} className="m-auto" /></div>
  );
};

export const SubscribeIcon = ({ size = 'md', className }) => {
  return (
    <div className={`oval-${size} bg-dark d-flex ${className}`}><FontAwesomeIcon icon={faBell} color="#fff" size={sizeToFASize[size]} className="m-auto" /></div>
  );
};

export const SubscribeSuccessIcon = ({ size = 'md', className }) => {
  return (
    <div className={`oval-${size} bg-dark d-flex ${className}`}><FontAwesomeIcon icon={faCheck} color="#fff" size={sizeToFASize[size]} className="m-auto" /></div>
  );
};


