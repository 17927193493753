import React, { useContext } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import UnsubscribePage from "./pages/UnsubscribePage";
import IncidentDetailPage from "./pages/IncidentDetailPage";
import HomePage from "./pages/HomePage";
import { usePayloadContext } from "components/usePayloadContext";
import { DarkContext } from "../components/DarkContextProvider";
import Footer from "./components/Footer";

const NuncV2 = () => {
  const { isDark } = useContext(DarkContext);
  const { isLoaded, error } = usePayloadContext();

  return (
    <div className={isDark ? "dark" : ""}>
      <div className="bg-[#f8f8f8] dark:bg-fh-gray-dark-2 min-h-screen">
        <div className="text-fh-gray-light-12 dark:text-fh-gray-dark-12">
          <div className="mx-auto py-10 px-4 max-w-7xl">
            <div className="mx-auto text-fh-gray-light-12 dark:text-fh-gray-dark-12">
              <BrowserRouter>
                <Header />
                {error && (
                  <h2>
                    An error happened with loading the status page data. Please
                    try again.
                  </h2>
                )}
                {isLoaded && (
                  <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route
                      path={"/incidents/:incidentId"}
                      element={<IncidentDetailPage />}
                    />
                    <Route
                      path={"/unsubscribe/:token"}
                      element={<UnsubscribePage />}
                    />
                    <Route path={"*"} element={<Navigate to="/" />} />
                  </Routes>
                )}
                <Footer />
              </BrowserRouter>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NuncV2;
