import React from "react";
import Tag from "./common/Tag";
import {
  TeamIcon,
  TreeFinal,
  TreeMiddle,
  SlackIcon,
} from "v2/components/Icons";
import { Link } from "react-router-dom";
import Markdown from "components/shared/Markdown";
import { findIncident } from "v2/helpers/incidents";
import ExternalLink from "./common/ExternalLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import Timestamp from "./common/Timestamp";

const faviconLookup = "https://www.google.com/s2/favicons?sz=64&domain=";

const IncidentLinks = ({ incident }) => {
  if (!incident?.links?.length) return null;
  return (
    <AdditionalDetailSection title="Links">
      <div className="flex flex-col gap-2">
        {incident?.links?.map((link) => (
          <div key={link.name} className="flex items-center" key={link.hrefUrl}>
            <img
              src={`${faviconLookup}${link.hrefUrl}`}
              className="w-[20px] h-[20px] mr-1"
              alt={link?.displayText}
            />
            <ExternalLink href={link.hrefUrl}>{link?.displayText}</ExternalLink>
          </div>
        ))}
      </div>
    </AdditionalDetailSection>
  );
};

const IncidentRelatedSection = ({ allIncidents, incident }) => {
  const relatedIncidents = [];
  if (incident?.relatedIncidents?.parentIncident) {
    const parentIncident = findIncident(
      allIncidents,
      incident.relatedIncidents.parentIncident?.id
    );
    if (parentIncident) relatedIncidents.push(parentIncident);
  }
  if (incident?.relatedIncidents?.childIncidents) {
    incident.relatedIncidents.childIncidents.forEach((child) => {
      const childIncident = findIncident(allIncidents, child?.id);
      if (childIncident) relatedIncidents.push(childIncident);
    });
  }
  if (relatedIncidents?.length === 0) return null;
  return (
    <AdditionalDetailSection title="Related Incidents">
      <div className="flex flex-col pl-2">
        <div className="flex mb-2">
          <h5>{incident?.title} (viewing)</h5>
        </div>
        {relatedIncidents?.map((relatedIncident, index) => {
          return (
            <div
              key={relatedIncident.id}
              className="flex ml-2 mb-2"
              key={relatedIncident?.id}
            >
              <div className="dark:text-fh-gray-dark-11">
                {index === relatedIncidents.length - 1 ? (
                  <TreeFinal />
                ) : (
                  <TreeMiddle />
                )}
              </div>
              <Link
                className="text-fh-blue-light-11 dark:text-fh-blue-dark-11 hover:underline pl-2"
                to={`/incidents/${relatedIncident?.id}`}
              >
                {relatedIncident?.title}
              </Link>
              <hr />
            </div>
          );
        })}
      </div>
    </AdditionalDetailSection>
  );
};

const IncidentPills = ({ items, title }) => {
  return (
    <div className="flex flex-wrap gap-y-2">
      {items?.length > 0 &&
        items?.map((item, i) => (
          <Tag
            key={`pill-${item}`}
            variant="greyed"
            size="xxs"
            key={`${item}-${i}`}
          >
            {item}
          </Tag>
        ))}
    </div>
  );
};

const AdditionalDetailSection = ({ title, children }) => (
  <div className="flex flex-col gap-1.5">
    <h3 className="text-sm">{title}</h3>
    <div className="text-sm dark:text-fh-gray-dark-11 text-fh-gray-light-11">
      {children}
    </div>
  </div>
);

const RespondersSection = ({ incident, exposedFields, index }) => {
  const responderIndex = exposedFields.indexOf("default/responders");
  const teamIndex = exposedFields.indexOf("default/teams");
  const lowerIndex = Math.min(responderIndex, teamIndex);
  // If we expose both teams and responders and we have already rendered
  // this block above. Don't render it again.
  // We have to do this because we expose both teams and responders but in
  // the UI we group them together
  if (responderIndex > -1 && teamIndex > -1 && index > lowerIndex) {
    return null;
  }

  return (
    <AdditionalDetailSection title="Responders">
      <div className="flex flex-wrap place-content-start gap-1">
        {responderIndex > -1 &&
          incident?.responders?.map((responder, i) => (
            <div
              key={responder.id}
              className="flex w-full"
              key={`${responder?.name}-${i}`}
            >
              <p className="text-md text-fh-gray-light-12 dark:text-fh-gray-dark-12">
                {responder?.name}{" "}
                <span className="text-[10px] text-fh-gray-light-11 dark:text-fh-gray-dark-11">
                  {responder?.role}
                </span>
              </p>
            </div>
          ))}
        {teamIndex > -1 &&
          incident?.teams?.map((team, i) => (
            <div
              className="flex w-[25%] pr-3 pb-2 items-center"
              key={`${team?.name}-${i}`}
            >
              <TeamIcon />
              <p className="ml-2 text-md">
                {team?.name}{" "}
                <span className="text-fh-gray-light-12 dark:text-fh-gray-dark-12 text-xs">
                  Team
                </span>
              </p>
            </div>
          ))}
        {!incident?.responders?.length && !incident?.teams?.length && "None"}
      </div>
    </AdditionalDetailSection>
  );
};

const LabelsSection = ({ incident }) => {
  const labels = Object.keys(incident?.labels || {})?.map(
    (key) => `${key}:${incident?.labels[key]}`
  );
  return (
    <AdditionalDetailSection title="Labels">
      {labels.length === 0 && "None"}
      {labels.length > 0 && <IncidentPills items={labels} />}
    </AdditionalDetailSection>
  );
};

const CustomerImpactSection = ({ incident }) => {
  return (
    <AdditionalDetailSection title="Customer Impact Summary">
      {incident?.customerImpactSummary && (
        <Markdown source={incident?.customerImpactSummary} />
      )}

      {!incident?.customerImpactSummary && "None"}
    </AdditionalDetailSection>
  );
};

const TagsSection = ({ incident }) => {
  return (
    <AdditionalDetailSection title="Tags">
      {(!incident?.tags || incident?.tags?.length === 0) && "None"}
      {incident?.tags?.length > 0 && (
        <IncidentPills items={incident?.tags || []} />
      )}
    </AdditionalDetailSection>
  );
};

const IncidentDescription = ({ incident }) => {
  return (
    <AdditionalDetailSection title="Description">
      {incident?.description && <Markdown source={incident?.description} />}
      {!incident?.description && "None"}
    </AdditionalDetailSection>
  );
};

const CommandCenterSection = ({ incident }) => (
  <div className="flex items-center text-sm">
    <FontAwesomeIcon
      icon={faLock}
      className="ml-1 text-fh-gray-light-11 dark:text-fh-gray-dark-11"
    />
    <ExternalLink className="ml-2" href={incident?.commandCenter?.hrefUrl}>
      FireHydrant Command Center
    </ExternalLink>
  </div>
);

const SlackChannelSection = ({ incident }) => {
  if (!incident?.slackChannel?.link) return null;
  return (
    <div className="flex items-center text-sm">
      <SlackIcon />
      <ExternalLink href={incident?.slackChannel?.link?.hrefUrl}>
        {incident?.slackChannel?.appLink?.displayText}
      </ExternalLink>
    </div>
  );
};

const ConferenceBridgesSection = ({ incident }) => {
  if (incident?.conferenceBridges?.length === 0) return null;
  return incident?.conferenceBridges?.map((bridge) => (
    <div className="flex items-center text-sm">
      <img
        src={`${faviconLookup}${bridge.hrefUrl}`}
        className="w-[20px] h-[20px] mr-1"
        alt={bridge?.displayText}
      />
      <ExternalLink href={bridge?.hrefUrl}>{bridge?.displayText}</ExternalLink>
    </div>
  ));
};

export const CustomFieldSection = ({ incident, customFieldId }) => {
  const { incidentDetails } = incident || {};
  const customField = incidentDetails?.find(({ id }) => id === customFieldId);

  if (!customField) return null;

  const { value, type } = customField;
  const date = value ? new Date(value) : null;

  const content =
    value && !isNaN(date?.getTime()) ? (
      <Timestamp timestamp={date} />
    ) : (
      <Markdown source={value || "None"} />
    );

  return (
    <AdditionalDetailSection title={type}>{content}</AdditionalDetailSection>
  );
};

export const IncidentFieldTypes = {
  "default/description": IncidentDescription,
  "default/links": IncidentLinks,
  "default/related_incidents": IncidentRelatedSection,
  "default/responders": RespondersSection,
  "default/teams": RespondersSection,
  "default/tags": TagsSection,
  "default/labels": LabelsSection,
  "default/customer_impact_summary": CustomerImpactSection,
  "default/slack_channel": SlackChannelSection,
  "default/command_center": CommandCenterSection,
  "default/conference_bridges": ConferenceBridgesSection,
};
