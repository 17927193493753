import dayjs from "dayjs";

const addIncidentToMap = (incident, incidentMap) => {
  const incidentStartedAt = dayjs(incident.timestamps.started);
  const year = incidentStartedAt.year();
  const month = incidentStartedAt.month(); // NB: Jan==0
  const day = incidentStartedAt.date();
  incidentMap[year] = incidentMap[year] || {};
  const yearMap = incidentMap[year];
  yearMap[month] = yearMap[month] || {};
  const monthMap = yearMap[month];
  monthMap[day] = monthMap[day] || [];
  const incidentsForDay = monthMap[day];
  incidentsForDay.push(incident);
};

const isIncidentResolved = (incident) =>
  !!(
    incident.timestamps.closed ||
    incident.timestamps.resolved ||
    incident.timestamps.postmortem_started ||
    incident.timestamps.postmortem_completed
  );

const mergeConditionsForActiveIncident = (incident, componentMap) => {
  if (isIncidentResolved(incident) || !incident.componentConditions) {
    return componentMap; // If the incident is not actice (is resolved) then we don't want to include the component conditions from this incident or the field is missing
  }

  const newMap = Object.assign({}, componentMap);
  const componentConditions = incident.componentConditions;
  const mapFromIncident = Object.keys(componentConditions).reduce((m, k) => {
    m[k] = {
      name: k,
      customerCondition: componentConditions,
    };
    return m;
  }, {});

  return Object.assign(newMap, mapFromIncident);
};

const mapCustomerConditions = (componentConditions, conditionsMap) => {
  for (const c of componentConditions) {
    if (!c.customerCondition) {
      c.customerCondition = DefaultCustomerCondition;
    }
    c.nuncCondition = nuncConditionForCustomerCondition(
      c.customerCondition,
      conditionsMap
    );
  }

  return componentConditions;
};

export const OperationalCondition = "OPERATIONAL";

export const DefaultCustomerCondition = "No Issues";
export const DefaultNuncCondition = OperationalCondition;

const nuncConditionForCustomerCondition = (
  customerCondition,
  conditionMap,
  defaultNuncCondition = DefaultNuncCondition
) => {
  const condition = customerCondition || DefaultCustomerCondition;

  return conditionMap[condition] || defaultNuncCondition;
};

export const processIncidents = ({
  incidents = [],
  components = [],
  conditions = {},
}) => {
  let componentMap = components.reduce((m, item) => {
    m[item.name] = { name: item.name };
    return m;
  }, {});

  const incidentMap = {};
  const activeIncidents = [];
  incidents.forEach((item) => {
    item.timeline = item.timeline ? item.timeline : [];
    addIncidentToMap(item, incidentMap);
    if (!isIncidentResolved(item)) {
      activeIncidents.push(item);
    }
    componentMap = mergeConditionsForActiveIncident(item, componentMap);
  });

  const componentConditions = mapCustomerConditions(
    Object.values(componentMap),
    conditions
  );
  return {
    incidentMap: incidentMap,
    componentConditions: componentConditions,
    activeIncidents: activeIncidents.reverse(),
    allIncidents: incidents.reverse(),
  };
};

export const findIncident = (allIncidents, incidentId) => {
  return allIncidents.find((incident) => incident.id === incidentId);
};
