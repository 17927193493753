import React, { useState, useEffect } from 'react';
import PayloadContext from './PayloadContext';
import { processIncidents } from 'v2/helpers/incidents';
import usePeriodically from 'hooks/usePeriodically';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);

const apiUrl = process.env.REACT_APP_API_URL || '';
const payloadUrl = `${apiUrl}/data/payload.json`;

const getPayload = async () => {
  const response = await fetch(payloadUrl);
  const data = await response.json();
  return {
    config: data.config || {},
    components: data.components || [],
    conditions: data.conditions || {},
    ...processIncidents({
      incidents: data.incidents,
      components: data.components,
      conditions: data.conditions,
    }),
    scheduledMaintenances: data.scheduledMaintenances || [],
    componentGroups: data.componentGroups || [],
    rawPayload: data,
  };
};

const getGMTStamp = () => {
  return dayjs(new Date().getTime())
    .tz('GMT')
    .format('ddd, DD MMM YYYY HH:mm:ss [GMT]');
};

// This is the source of all data that is used in the app.
// It is loaded from the API and then accessed by using usePayloadContext
// We do this because we need the config.ui_version flag loaded in the app
// before we know which version to render.
//
//    config: config holds title, ui_version, companyName, etc.
//    components: a array of configured services, envrionments, functionatlities
//    conditions: selected values from the severity matrix
//    scheduledMaintenances: non incident maintenances
//    componentGroups: configured in the admin UI, parents to components
//    rawPayload: the full payload to use for V1

const PayloadContextProvider = ({ children }) => {
  const [lastModifiedTime, setLastModifiedTime] = useState(null);
  const [payloadState, setPayloadState] = useState({
    isLoading: false,
    isLoaded: false,
    error: null,
  });
  const loadData = async () => {
    try {
      setPayloadState({
        ...payloadState,
        isLoading: true,
        error: null,
      });
      const data = await getPayload();
      setPayloadState({
        ...data,
        isLoading: false,
        isLoaded: true,
        error: null,
      });
      setLastModifiedTime(getGMTStamp());
    } catch (err) {
      setPayloadState({
        isLoaded: false,
        isLoading: false,
        error: err,
      });
    }
  };
  usePeriodically(
    async () => {
      loadData();
    },
    lastModifiedTime ? 30000 : null,
  );

  useEffect(() => {
    loadData();
  }, []);

  return (
    <PayloadContext.Provider value={payloadState}>
      {children}
    </PayloadContext.Provider>
  );
};

export default PayloadContextProvider;
