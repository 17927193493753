import React from "react";
import { Link } from "react-router-dom";

const ActiveIncidentAlert = ({ incidentCount }) => {
  return (
    <Link to="/">
      <span className="text-white badge badge-pill badge-incident py-2 mb-3 mr-3">
        {`${incidentCount} incident${incidentCount > 1 ? "s" : ""}`}
      </span>
    </Link>
  );
};

export default ActiveIncidentAlert;
