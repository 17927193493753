import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useRecaptcha } from '../../../hooks/useRecaptcha';

import { submitSubscription } from '../../../actions/subscribeViaEmail';
import { emailSubscriptionError } from '../../../actions/actions';

import { email } from '../../../helpers/validators';

export const SubscriptionForm = () => {
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();
  const [emailValue, setEmail] = useState('');
  const subscriptionErrors = useSelector(state => state.api.subscriptionRequestError);
  const invalidEmail = email(emailValue) === 'Invalid email address' || !emailValue;

  const siteKey = useSelector(state => state.statusPage.config.recaptchaKey);
  const validateRecaptcha = useRecaptcha({ siteKey: siteKey, hideDefaultBadge: true });

  const isRecaptchaActive = false; // Should be a feature flag but we don't have LD in this code base
  const recaptchaValidator = isRecaptchaActive ? validateRecaptcha : ((_) => Promise.resolve("dummy"));

  const onSubmit = (data) => {
    recaptchaValidator("subscribe")
      .then(t => dispatch(submitSubscription({ ...data, recaptchaToken: t })))
      .catch(e => { console.warn("Subscription error", e); dispatch(emailSubscriptionError(new Error("you may be a robot"))) }); // TODO: I don't know how to tell if this fails... and if it is captcha specific
  };

  const getErrorMessage = () => {
    let errorMessage = '';
    if (subscriptionErrors) errorMessage = 'Oops, an error occurred. Please try again later.'
    return errorMessage;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group">
        <input type="email" placeholder="Enter email address" name="Email" className="mt-2 mb-1 input" ref={register({ required: true, pattern: /^\S+@\S+$/i })} onChange={(e) => setEmail(e?.target?.value)} />
        {getErrorMessage() && <div className="text-error text-sm">{getErrorMessage()}</div>}
        <button type="submit" className="btn btn-block btn-primary mt-1" disabled={invalidEmail}>Submit</button>
      </div>
    </form>
  );
}

export default SubscriptionForm;