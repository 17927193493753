import {
  DEGRADED,
  OFFLINE,
  OPERATIONAL,
  SCHEDULED_MAINTENANCE,
} from "./conditions";

export const statusColors = {
  [DEGRADED]: "amber-9",
  [OPERATIONAL]: "green-9",
  [OFFLINE]: "tomato-9",
  [SCHEDULED_MAINTENANCE]: "blue-9",
};
