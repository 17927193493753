import React from 'react';
import PropTypes from 'prop-types';
import IncidentTimeline, { LastEventFilter } from './IncidentTimeline';
import { MarqueeEvent } from './IncidentEvent';

export const IncidentSummary = ({ incident }) => {
  return (
    <div className="incident-summary" key={`incident-summary-${incident.id}`}>
      <IncidentTimeline timeline={incident.timeline} timelineFilter={LastEventFilter} EventType={MarqueeEvent} />
    </div>
  );
};

IncidentSummary.propTypes = {
  incident: PropTypes.shape(),
  showTitle: PropTypes.bool,
  severity: PropTypes.string,
};

export default IncidentSummary;