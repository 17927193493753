import React from "react";
import Markdown from "components/shared/Markdown";
import { BulkEventPBType, NotePBType } from "v2/constants/events";

export const ComponentUpdate = ({ details }) => (
  <div className="mb-0 mt-2">
    {details?.map((c) => (
      <p key={c.component}>
        <strong>{c.component}</strong> has transitioned to{" "}
        <em>{c.condition}</em>
      </p>
    ))}
  </div>
);

const NoteUpdate = ({ details }) => {
  if (!details?.note) return null;
  return (
    <div className="mt-2">
      <Markdown source={details.note} />
    </div>
  );
};

const BulkUpdate = ({ details }) => (
  <>
    {details?.componentUpdates && (
      <ComponentUpdate details={details.componentUpdates} />
    )}
    {details?.note && <NoteUpdate details={details.note} />}
  </>
);

const TimelineEventTypes = {
  [NotePBType]: NoteUpdate,
  [BulkEventPBType]: BulkUpdate,
};

export default TimelineEventTypes;
