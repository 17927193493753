import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { conditionIcons } from '../../helpers/conditionHelpers';
import { useSelector } from 'react-redux';

const IncidentAffectedComponents = ({ components = {} }) => {
  const conditionMap = useSelector(state => state.statusPage.conditionMap);

  let componentList = <p className="incident-affected-component text-sm py-3 mb-0"><em>No components affected</em></p>

  if (Object.keys(components).length > 0) {
    componentList = Object.keys(components).map((c, index) => {
      const icon = conditionIcons[conditionMap[components[c]]]
      let border = 'border-bottom';
      if (index === Object.keys(components).length - 1) {
        border = '';
      }
      return (
        <div className="row mx--2" key={`component-${c}`}>
          <div className={`col-12 d-flex justify-content-between px-2 py-3 ${border}`}>
            <p className="mb-0 text-sm">
              {c}
            </p>
            {icon && icon({ className: 'd-inline' })}
          </div>
        </div>
      )
    })
  }

  return (
    <Card className="no-shadow">
      <CardHeader className="py-2 bg-dark pl-3">
        <h4 className="text-white mb-0">This incident affects</h4>
      </CardHeader>
      <CardBody className="border rounded-bottom border-top-0 py-0 px-3">
        {componentList}
      </CardBody>
    </Card >
  )
}

IncidentAffectedComponents.propTypes = {
  components: PropTypes.shape(),
};

export default IncidentAffectedComponents;
