import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import moment from "moment";
import { Card, CardBody, CardHeader } from "reactstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import IncidentHistorySummary from "./IncidentHistorySummary";
import IncidentsForTimespan from "./IncidentsForTimespan";
import {
  createHistoryIterator,
  DayUnit,
  MonthUnit,
  WeekUnit,
} from "../../helpers/incidentHistoryIterator";
import { incidentsSelector } from "../../helpers/stateSelectors";
import { sortIncidentsByMostRecentUpdate } from "../../helpers/sortIncidents";

const DefaultNumberOfDays = 14;

export const IncidentHistory = ({
  title,
  daysToShow = DefaultNumberOfDays,
}) => {
  const incidents = useSelector(incidentsSelector);
  const incidentsExcludingMaintenance = incidents.filter(
    (incident) => incident.severitySlug !== "MAINTENANCE"
  );

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const range = {
    from: moment().endOf(DayUnit).subtract(daysToShow, DayUnit),
    until: moment().endOf(DayUnit),
  };
  const incidentIter = createHistoryIterator({
    incidents: incidentsExcludingMaintenance,
    stepSize: [2, WeekUnit],
    ...range,
  });
  let incidentCount = 0;

  const days = Array.from(incidentIter).map((span, i, array) => {
    if (i === array.length - 1) return null;
    incidentCount += span.incidents.length;

    const sortedIncidents = sortIncidentsByMostRecentUpdate(span.incidents);

    return (
      <IncidentsForTimespan
        incidents={sortedIncidents}
        date={span.from}
        Child={IncidentHistorySummary}
        key={span.from.format("YYYY-MM-DD")}
        viewType={"INCIDENT_HISTORY"}
      />
    );
  });

  return (
    <Card className="no-shadow">
      <CardHeader className="border-bottom-0">
        <div className="row">
          <div className="col-12">
            <h2 className="mb-0">
              {incidentCount} incident{incidentCount === 1 ? "" : "s"} in the
              last 2 weeks
            </h2>
            <p className="text-sm border-bottom pb-2">
              {range.from.format("MMMM DD, YYYY")} -{" "}
              {range.until.format("MMMM DD, YYYY")}
            </p>
          </div>
        </div>
      </CardHeader>
      <CardBody className="py-0">
        {days}
        {pathname === "/" && (
          <button
            onClick={() => navigate("/history")}
            className="btn btn-link p-0 text-dark"
          >
            <FontAwesomeIcon icon={faCalendarAlt} />
            <span>View incident history</span>
          </button>
        )}
      </CardBody>
    </Card>
  );
};

IncidentHistory.propTypes = {
  title: PropTypes.string,
  daysToShow: PropTypes.number,
};

export default IncidentHistory;
