import React, { useState } from 'react';
import useScript from 'react-script-hook';

const isBrowser =
  typeof window !== 'undefined' && typeof window.document !== 'undefined';

const injectStyle = (rule) => {
  const styleEl = document.createElement('style');
  document.head.appendChild(styleEl);

  const styleSheet = styleEl.sheet;
  if (styleSheet) styleSheet.insertRule(rule, styleSheet.cssRules.length);
};

export const useRecaptcha = ({ siteKey, hideDefaultBadge = false }) => {
  const [recaptcha, setRecaptcha] = useState();

  React.useEffect(() => {
    if (isBrowser && hideDefaultBadge) {
      injectStyle('.grecaptcha-badge { visibility: hidden; }');
    }
  }, [hideDefaultBadge]);

  useScript({
    src: `https://www.google.com/recaptcha/api.js?render=${siteKey}`,
    onload: () => {
      window.grecaptcha.ready(() => {
        setRecaptcha(window.grecaptcha);
      })
    },
  });

  React.useEffect(() => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(() => {
        setRecaptcha(window.grecaptcha);
      });
    }
  }, []);

  return (action) => {
    return new Promise(resolve => {
      if (recaptcha) {
        resolve(recaptcha.execute(siteKey, { action }));
      }
    });
  };
}

