import React from "react";
import PropTypes from "prop-types";
import { StatusIcons } from "./Icons";

import IncidentHoverWrapper from "./common/IncidentHoverWrapper";
import Tooltip from "./common/Tooltip";
import { statusColors } from "v2/constants/colors";

const HistogramBar = ({
  tooltipName,

  dayInTime,
  incidents,
  currentCondition,
}) => {
  const color = statusColors[currentCondition];
  return (
    <div className="max-w-[25px] w-full h-[25px]">
      <Tooltip
        className="w-full h-full"
        tooltipContent={
          <>
            <p className="text-sm dark:text-fh-gray-6 px-2">
              {dayInTime.format("ddd, MMM D, YYYY")}
            </p>
            {incidents?.map((incident) => {
              const StatusIcon = StatusIcons[currentCondition];
              return (
                <IncidentHoverWrapper
                  key={`${tooltipName}-${incident.id}`}
                  incidentId={incident.id}
                  className="px-2 py-1"
                >
                  <div className="flex items-center min-w-[200px] mt-2">
                    {StatusIcon && (
                      <StatusIcon className="w-[16px] h-[16px] mr-2" />
                    )}
                    {incident?.title}
                  </div>
                </IncidentHoverWrapper>
              );
            })}
          </>
        }
      >
        <div
          className={`bg-fh-${color} max-w-[25px] w-full h-[25px] rounded-sm cursor-pointer`}
        />
      </Tooltip>
    </div>
  );
};

HistogramBar.propTypes = {
  tooltipName: PropTypes.string.isRequired,
  color: PropTypes.string,
  conditions: PropTypes.object,
  isHoverActive: PropTypes.bool.isRequired,
  dayInTime: PropTypes.object.isRequired,
  incidents: PropTypes.array,
};

export default HistogramBar;
