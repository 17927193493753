import React from "react";
import PropTypes from "prop-types";
import { ExternalIcon } from "v2/components/Icons";

const ExternalLink = ({ children, className = "", variant = "", ...props }) => {
  let linkClass = "text-fh-blue-light-11 dark:text-fh-blue-dark-11";
  if (variant === "plain") {
    linkClass = "text-fh-light-gray-12 dark:text-fh-dark-gray-12";
  }
  return (
    <a
      target="_blank"
      rel="noreferrer"
      className={`${linkClass} items-center hover:underline ${className}`}
      {...props}
    >
      {children}
      <ExternalIcon className="inline-block ml-2 relative top-[-1px]" />
    </a>
  );
};

ExternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.string,
};

export default ExternalLink;
