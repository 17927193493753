import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Popover } from "@headlessui/react";
import SubscriptionForm from "./SubscriptionForm";
import { DarkContext } from "../../components/DarkContextProvider";
import { usePayloadContext } from "components/usePayloadContext";
import ExternalLink from "./common/ExternalLink";

const baseURL = process.env.REACT_APP_API_URL || "";

const Header = () => {
  const [hasLogo, setHasLogo] = useState(true);
  const [hasDarkLogo, setHasDarkLogo] = useState(true);
  const { config } = usePayloadContext();
  const { isDark } = useContext(DarkContext);
  const logoURL =
    isDark && hasDarkLogo
      ? `${baseURL}/data/dark_logo.png`
      : `${baseURL}/data/logo.png`;
  return (
    <nav className='mb-4'>
      <div className='flex justify-between py-4 items-center'>
        <Link to='/' className='flex items-center max-w-[calc(100%-120px)]'>
          {hasLogo ? (
            <img
              src={logoURL}
              className='md:max-w-[50%] max-w-[75%] mr-2'
              onError={() => {
                if (isDark && hasDarkLogo) {
                  setHasDarkLogo(false);
                } else {
                  setHasLogo(false);
                }
              }}
              alt='logo'
            />
          ) : (
            <h1 className='text-2xl'>{config?.title}</h1>
          )}
        </Link>
        <div className='flex items-center pl-2 '>
          {config?.links?.map((link) => (
            <ExternalLink
              href={link?.hrefUrl}
              key={`link-${link?.id}`}
              className='mr-[20px] text-md font-medium whitespace-nowrap md:block hidden'
              variant='plain'
            >
              {link?.displayText}
            </ExternalLink>
          ))}
          <Popover className='relative'>
            <Popover.Button className='transition duration-350 ease-in-out text-sm rounded font-medium py-2.5 px-5 text-white dark:text-fh-gray-dark-1 opacity-90 border border-fh-gray-6 dark:border-fh-gray-dark-6 hover:opacity-100 shadow-xl bg-gradient-to-r from-[#292624] dark:from-[#f8f8f8] to-[#343330] dark:to-[#dcdcdc]'>
              Subscribe
            </Popover.Button>
            <Popover.Panel className='absolute z-10 right-0 top-14 w-full min-w-[350px] shadow-xl'>
              <SubscriptionForm />
            </Popover.Panel>
          </Popover>
        </div>
      </div>
      <div className='flex flex-col items-start w-full mb-4'>
        {config?.links?.map((link) => (
          <ExternalLink
            href={link?.hrefUrl}
            key={`link-${link?.id}`}
            className='mr-[20px] text-md font-medium whitespace-nowrap md:hidden block '
            variant='plain'
          >
            {link?.displayText}
          </ExternalLink>
        ))}
      </div>
    </nav>
  );
};

export default Header;
