import { SET_GLOBAL_ALERT, DISMISS_GLOBAL_ALERT } from '../actions/actions';

const initialState = {
  alerts: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_GLOBAL_ALERT: {
      // Clone alerts and add new one.
      const newAlerts = Array.from(state.alerts);
      newAlerts.push(action.alert);
      return { ...state, alerts: newAlerts };
    }
    case DISMISS_GLOBAL_ALERT: {
      // Delete the alert (or at least the first of this message/level) from the alerts.
      const newAlerts = Array.from(state.alerts);
      const idx = newAlerts.indexOf(action.alert);
      if (idx !== -1) {
        newAlerts.splice(idx, 1)
      }

      return { ...state, alerts: newAlerts }; // Should we record that there has been a succes?
    }
    default:
      return state;
  }
};