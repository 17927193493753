import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  getMostSevereComponent,
  conditionIcons,
  DegradedCondition,
} from "../../helpers/conditionHelpers";

import { relativeIncidentUrl } from "../../helpers/urls";

export const lastUpdate = (incident, format = "MMMM DD, YYYY | hh:mma") => {
  const milestoneOrder = [
    "started",
    "detected",
    "acknowledged",
    "investigating",
    "identified",
    "mitigated",
    "resolved",
  ];

  if (incident.timeline.length > 0) {
    return moment(incident.timeline[0].time).format(format);
  }
  let time = incident.timestamps.started;
  milestoneOrder.forEach((m) => {
    if (incident.timestamps[m]) {
      time = incident.timestamps[m];
    }
  });

  return moment(time).format(format);
};

export const IncidentLink = ({ incident }) => {
  const conditionMap = useSelector((state) => state.statusPage.conditionMap);
  const component = getMostSevereComponent(
    incident.componentConditions,
    conditionMap
  );

  const icon = component
    ? conditionIcons[
        conditionMap[component.customerCondition] || DegradedCondition
      ]
    : conditionIcons[DegradedCondition];
  return (
    <div className="text-center">
      {icon({ size: "xl", className: "mx-auto" })}
      <Link to={relativeIncidentUrl(incident)}>
        <h1 className="mt-3">{incident.title}</h1>
      </Link>
      <p>
        Updated on {lastUpdate(incident, "MMMM DD, YYYY [at] hh:mma")} |{" "}
        <Link to={relativeIncidentUrl(incident)}>
          <strong>Go to incident</strong>{" "}
          <FontAwesomeIcon icon={faChevronRight} />
          <FontAwesomeIcon icon={faChevronRight} />
        </Link>
      </p>
      <div className="separator mx-auto"></div>
    </div>
  );
};

IncidentLink.propTypes = {
  incident: PropTypes.shape(),
  linkText: PropTypes.string,
  className: PropTypes.string,
};

export default IncidentLink;
