import {
  CUSTOMER_RSS_URL,
  EMAIL_SUBSCRIPTION_URL,
} from "../constants/constants";
import { useRecaptcha } from "../../hooks/useRecaptcha";

import React from "react";
import { useState } from "react";
import { usePayloadContext } from "components/usePayloadContext";

const SubscriptionForm = () => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { config } = usePayloadContext();

  const siteKey = config?.recaptchaKey;
  const validateRecaptcha = useRecaptcha({
    siteKey: siteKey,
    hideDefaultBadge: true,
  });

  const isRecaptchaActive = false; // Should be a feature flag but we don't have LD in this code base
  const recaptchaValidator = isRecaptchaActive
    ? validateRecaptcha
    : (_) => Promise.resolve("dummy");
  return (
    <form
      method="post"
      onSubmit={async (e) => {
        setError(false);
        setSuccess(false);
        e.preventDefault();
        const formData = new FormData(e.currentTarget);

        try {
          await recaptchaValidator("subscribe")
            .then(
              async (t) =>
                await fetch(EMAIL_SUBSCRIPTION_URL, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    email: formData.get("email"),
                    recaptcha_token: t,
                  }),
                })
            )
            .then((res) => setSuccess(true));
        } catch (error) {
          console.warn("Subscription error", e);
          // TODO: I don't know how to tell if this fails... and if it is captcha specific
          setError(true);
        }
      }}
    >
      <div className="bg-white dark:bg-fh-gray-dark-3 border rounded border-fh-gray-6 dark:border-fh-gray-dark-6 p-6">
        <div className="flex flex-col justify-between items-center gap-4 mb-4">
          <div className="rounded-full bg-fh-gray-light-12 text-white dark:bg-fh-gray-dark-6 dark:text-fh-gray-light-3 p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-4 h-4"
            >
              <path
                fillRule="evenodd"
                d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <p className="text-md text-center dark:text-fh-gray-6 flex-1 px-8 mb-3">
            Stay updated on incidents and upcoming maintenance
          </p>
        </div>
        {error && (
          <p className="mt-4 text-sm bg-fh-tomato-9 p-2 text-white rounded">
            Something went wrong while trying to subscribe. Please try again.
          </p>
        )}
        <div className="mt-4">
          <div className="flex flex-col gap-4">
            <input
              type="email"
              name="email"
              className="border w-full rounded p-3.5 text-sm text-black dark:text-white dark:bg-fh-gray-dark-6 dark:border-fh-gray-dark-6"
              placeholder="Enter email address"
              autoFocus
            />
            <div className="flex flex-col gap-1.5">
              <button
                type="submit"
                className="transition duration-350 ease-in-out text-sm rounded font-medium py-2.5 px-5 text-white dark:text-black opacity-90 border border-fh-gray-6 dark:border-fh-gray-dark-6 hover:opacity-100 bg-gradient-to-r from-[#292624] dark:from-[#f8f8f8] to-[#343330] dark:to-[#dcdcdc]"
              >
                Submit
              </button>
              <a
                target="_blank"
                href={CUSTOMER_RSS_URL}
                className="flex gap-1 py-2.5 text-sm rounded items-center justify-center w-full bg-fh-gray-light-12 dark:text-fh-gray-dark-12 dark:hover:text-white bg-white text-fh-gray-light-11 hover:text-black bg-white  dark:bg-fh-gray-dark-6"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-4 h-4"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.75 4.5a.75.75 0 01.75-.75h.75c8.284 0 15 6.716 15 15v.75a.75.75 0 01-.75.75h-.75a.75.75 0 01-.75-.75v-.75C18 11.708 12.292 6 5.25 6H4.5a.75.75 0 01-.75-.75V4.5zm0 6.75a.75.75 0 01.75-.75h.75a8.25 8.25 0 018.25 8.25v.75a.75.75 0 01-.75.75H12a.75.75 0 01-.75-.75v-.75a6 6 0 00-6-6H4.5a.75.75 0 01-.75-.75v-.75zm0 7.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Subscribe with RSS
              </a>
            </div>
            {success && (
              <p className="mt-4 text-sm mt-4 text-sm bg-fh-green-9 p-2 text-white rounded text-white rounded">
                You will receive an email shortly confirming your request.
              </p>
            )}
          </div>
          <a
            href="https://app.firehydrant.io/legal/privacy"
            rel="noopener noreferrer"
            target="_blank"
            className="text-xs text-center mt-6 block dark:text-fh-gray-dark-11 text-fh-gray-light-11"
          >
            View <b>FireHydrant Privacy Policy</b>
          </a>
          <p className="text-xs text-center mt-1.5 mb-3 block dark:text-fh-gray-dark-11 text-fh-gray-light-11 px-4">
            We use ReCAPTCHA. The Google{" "}
            <a
              href="https://policies.google.com/privacy"
              rel="noopener noreferrer"
              target="_blank"
            >
              <b>Privacy Policy</b>
            </a>{" "}
            and{" "}
            <a
              href="https://policies.google.com/terms"
              rel="noopener noreferrer"
              target="_blank"
            >
              <b>Terms of Service</b>
            </a>{" "}
            apply.
          </p>
        </div>
      </div>
    </form>
  );
};

export default SubscriptionForm;