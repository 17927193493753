import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { IncidentTimeline } from "../shared/IncidentTimeline";
import IncidentAffectedComponents from "./IncidentAffectedComponents";
import { CardHeader, Card, CardBody } from "reactstrap";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { faSignal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { lastUpdate } from "../shared/IncidentLink";

export const IncidentDetails = ({
  incident,
  icon = () => "",
  conditionMap,
  condition = { customerCondition: "degraded" },
}) => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  };

  const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  };

  const { width } = useWindowDimensions();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{incident.title}</title>
      </Helmet>
      <Card className="no-shadow">
        <CardHeader className="border-bottom-0">
          <div className="row border-bottom justify-content-between">
            <div className="pl-0 pr-2">
              <div className="mb-4">
                <Link to="/" className="text-dark">
                  <FontAwesomeIcon icon={faSignal} className="pr-1" size="lg" />
                  View status page
                </Link>
              </div>
              <p className="text-sm mb-0">
                <strong>Updated:</strong> {lastUpdate(incident)}
              </p>
              <h1>{incident.title}</h1>
            </div>
            <div className="align-self-end pb-3">
              <div className="d-flex align-self-end">
                {conditionMap[condition.customerCondition] && (
                  <p className="ml-auto pr-2 d-inline mb-0 text-sm">
                    {conditionMap[condition.customerCondition]}
                  </p>
                )}
                {icon({ className: "d-inline" })}
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody className="pt-0">
          <div className="row justify-content-between">
            {width <= 768 && (
              <div className="col-12 px-0">
                <IncidentAffectedComponents
                  components={incident.componentConditions}
                />
              </div>
            )}
            <div className="col-12 col-md-8 border p-4 rounded mb-3">
              {incident.timeline && (
                <IncidentTimeline timeline={incident.timeline} />
              )}
              {!incident.timeline.length && (
                <div>
                  <em>No additional details recorded at this time</em>
                </div>
              )}
            </div>
            <div className="col-12 col-md-3 sticky px-0">
              {width > 768 && (
                <IncidentAffectedComponents
                  components={incident.componentConditions}
                />
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

IncidentDetails.propTypes = {
  incident: PropTypes.shape(),
};

export default IncidentDetails;
