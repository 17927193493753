import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { loadErrorSelector, initialLoadSelector } from '../../helpers/stateSelectors';

const InitialLoadMessage = () => {
  return (
    <Card className="no-shadow">
      <CardHeader className="border-bottom-0">
        <div className="text-center mb-4">
          <h3 className="mt-3">
            Loading...
          </h3>
        </div>
      </CardHeader>
    </Card>
  );
}

const LoadErrorMessage = () => {
  return (
    <Card className="no-shadow">
      <CardHeader className="border-bottom-0">
        <div className="text-center mb-4">
          <h3 className="mt-3">
            There was an error loading the page data. Try refreshing the page or contacting support@firehydrant.io for support.
          </h3>
        </div>
      </CardHeader>
    </Card>
  );
}

export const LoadingMessage = () => {
  const isInitialLoad = useSelector(initialLoadSelector);
  const isError = useSelector(loadErrorSelector);

  if (isInitialLoad !== true) {
    // Don't show anything if it's not the initial load since we don't want to show the message
    // if we've ever loaded before
    return (<></>);
  }

  if (isError === undefined) {
    return (<InitialLoadMessage />);
  }

  return (<LoadErrorMessage />);
};

export default LoadingMessage;