import React from "react";
import { useParams, useSearchParams, Link } from "react-router-dom";
import LinkButton from "v2/components/common/LinkButton";
import IncidentDetailTimeline from "../components/IncidentDetailTimeline";
import IncidentAffectedComponents from "../components/IncidentAffectedComponents";
import IncidentAdditionalDetails from "../components/IncidentAdditionalDetails";
import { LeftArrow } from "v2/components/Icons";
import { findIncident } from "v2/helpers/incidents";
import { usePayloadContext } from "components/usePayloadContext";
import Helmet from "react-helmet";

const Page404 = () => (
  <>
    <div className="flex flex-col justify-center items-center p-12">
      <h1 className="text-4xl mb-4">404: Incident not found</h1>
      <LinkButton to="/">Go home</LinkButton>
    </div>
  </>
);

const IncidentDetailPage = () => {
  const { allIncidents, config } = usePayloadContext();
  const { incidentId } = useParams();
  const [searchParams] = useSearchParams();
  const monthYear = searchParams.get("month-year");

  const incident = findIncident(allIncidents, incidentId);

  if (!incident && allIncidents?.length > 0) {
    return <Page404 />;
  } else if (!incident) {
    return null;
  }
  const getMonthYear = (timestamp) => {
    let url = "/";
    try {
      const parsedMonthYear = JSON.parse(timestamp);
      if (parsedMonthYear.year && parsedMonthYear.month !== undefined) {
        url += `?month-year=${parsedMonthYear.year}-${parsedMonthYear.month}`;
      }
    } catch (e) {
      url += `?month-year=${timestamp}`;
    }
    return url;
  };

  const backToStatusUrl = monthYear ? getMonthYear(monthYear) : "/";

  return (
    <>
      <Helmet>
        <title>
          {config?.companyName} Incident {incident?.title} Status
        </title>
      </Helmet>
      <div className="flex flex-col">
        <Link
          to={backToStatusUrl}
          className="flex items-center mb-4 text-sm opacity-60 hover:opacity-90 transition duration-150 ease-in-out"
        >
          <LeftArrow className="mr-2" />
          Back to system status
        </Link>
        <div className="grid lg:grid-cols-3 gap-12">
          <div className="lg:col-span-2 md:col-span-3">
            <IncidentDetailTimeline incident={incident} />
            <IncidentAffectedComponents incident={incident} />
          </div>
          <div className="lg:col-span-1 md:col-span-3">
            {config?.exposedFields?.length > 0 && (
              <IncidentAdditionalDetails
                incident={incident}
                exposedFields={config?.exposedFields}
                allIncidents={allIncidents}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default IncidentDetailPage;
