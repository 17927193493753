import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Markdown from "./Markdown";

export const NotePBType =
  "type.googleapis.com/firehydrant.nunc.TimelineEvent.Note";
export const MilestoneUpdatePBType =
  "type.googleapis.com/firehydrant.nunc.TimelineEvent.MilestoneUpdate";
export const BulkEventPBType =
  "type.googleapis.com/firehydrant.nunc.TimelineEvent.BulkImpactUpdate";

const elementForEvent = (event) => {
  let element = null;
  switch (event.details["@type"]) {
    case NotePBType:
      element = <IncidentNote details={event.details} />;
      break;
    case MilestoneUpdatePBType:
      element = <MilestoneUpdate details={event.details} />;
      break;
    case BulkEventPBType:
      element = <BulkUpdate details={event.details} />;
    default:
  }

  return element;
};

export const IncidentNote = ({ details }) => (
  <div className="py-2 my-2 px-3 bg-shade text-dark lh-130">
    <p className="mb-0 d-inline">
      <Markdown source={details.note} />
    </p>
  </div>
);

export const MilestoneUpdate = ({ details }) => {
  let milestoneText = (
    <>
      from <em>{milestoneCopy[details.originalMilestone]}</em> to{" "}
      <em>{milestoneCopy[details.currentMilestone]}</em>
    </>
  );
  if (details.currentMilestone === "started")
    milestoneText = (
      <>
        to <em>{milestoneCopy[details.currentMilestone]}</em>
      </>
    );
  return (
    <div className="py-2 text-dark lh-130">
      <strong>The milestone</strong> has transitioned{" "}
      <div className="mb-0 d-inline">{milestoneText}</div>
    </div>
  );
};

export const ComponentUpdate = ({ details }) => (
  <div className="text-dark leading-relaxed">
    {details.map((c, index) => (
      <div key={index} className="py-2 d-block">
        <strong>{c.component}</strong>
        <span className="d-inline-block">
          {" "}
          has transitioned to <em>{c.condition}</em>
        </span>
      </div>
    ))}
  </div>
);

export const milestoneCopy = {
  started: "Investigating",
  acknowledged: "Investigating",
  detected: "Investigating",
  investigating: "Investigating",
  identified: "Identified",
  mitigated: "Mitigated",
  resolved: "Resolved",
};

export const BulkUpdate = ({ details }) => (
  <>
    {details.milestoneUpdate && (
      <MilestoneUpdate details={details.milestoneUpdate} />
    )}
    {details.componentUpdates && (
      <ComponentUpdate details={details.componentUpdates} />
    )}
    {details.note && <IncidentNote details={details.note} />}
  </>
);

export const IncidentEvent = ({ event, showTimestamp }) => {
  const renderTimestamp = () => (
    <span className="d-block text-sm pb-2">
      <em>{moment(event.time).format("MMMM DD, YYYY")}</em>
      <span>{" | "}</span>
      <em>{moment(event.time).format("hh:mmA")}</em>
    </span>
  );

  return (
    <div className="row mb-3 pb-3 no-gutters border-bottom incident-event">
      <div className="col-12 px-0">
        {showTimestamp && renderTimestamp()}
        {elementForEvent(event)}
      </div>
    </div>
  );
};

export const MarqueeEvent = ({ event }) => {
  return (
    <div className="row mb-3">
      <div className="col-12 text-center">{elementForEvent(event)}</div>
    </div>
  );
};

IncidentEvent.propTypes = {
  details: PropTypes.shape(),
};

export default IncidentEvent;
