import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

const DissimisableAlert = ({ theAlert, onDismiss }) => {
  const [visible, setVisible] = useState(true);
  const toggle = () => {
    setVisible(false);
    onDismiss(theAlert);
  };
  return (<Alert color={theAlert.level} isOpen={visible} toggle={toggle} key={theAlert.message}>{theAlert.message}</Alert>);
};

const GlobalAlerts = ({ alerts = [], onDismiss = ((_) => { }) }) => (
  <div id="alerts">
    {alerts.map(a => (<DissimisableAlert theAlert={a} onDismiss={onDismiss} key={a.message} />))}
  </div>
);

GlobalAlerts.propTypes = {
  theAlert: PropTypes.shape(),
  onDismiss: PropTypes.func,
};

export default GlobalAlerts;