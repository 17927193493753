import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { SubscriptionWidget } from "./SubscriptionWidget";
import { useSelector } from "react-redux";
import ActiveIncidentAlert from "./ActiveIncidentAlert";
import MaintenanceAlert from "./MaintenanceAlert";

export const Header = ({ logoUrl }) => {
  const config = useSelector((state) => state.statusPage.config);
  const activeIncidents = useSelector(
    (state) => state.statusPage.activeIncidents
  );
  const activeMaintenances = useSelector(
    (state) => state.statusPage.activeMaintenances
  );
  const { pathname } = useLocation();

  return (
    <header className="align-items-center mb-5 bg-header-gradient py-4 px-5">
      <div className="row justify-content-between">
        <div className="flex row align-items-center mb-3">
          <a href={config && config.companyWebsite}>
            <img
              src={logoUrl}
              className="App-logo"
              alt="logo"
              style={{ height: "40px" }}
            />
          </a>
          <p className="border-left border-light d-inline text-white pl-2 ml-2 mb-0">
            Status
          </p>
        </div>
        <div className="text-md-right px-0">
          {activeIncidents.length > 0 && (
            <ActiveIncidentAlert incidentCount={activeIncidents.length} />
          )}
          {activeMaintenances && (
            <MaintenanceAlert maintenanceCount={activeMaintenances.length} />
          )}
          <SubscriptionWidget />
        </div>
      </div>
      {pathname === "/" && (
        <div className="row">
          <div className="col-12">
            <h2 className="text-white h1 mt-3">{config.greetingTitle}</h2>
            <p className="text-white">{config.greetingBody}</p>
          </div>
        </div>
      )}
    </header>
  );
};

Header.propTypes = {
  logoUrl: PropTypes.string,
};

export default Header;
