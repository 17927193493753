import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

import { SubscriptionPopover } from './SubscriptionPopover';

import { configSelector } from '../../../helpers/stateSelectors';
import { useSelector } from "react-redux";

export const SubscriptionWidget = () => {
  const config = useSelector(configSelector);

  return (
    <>
      <button className="btn btn-outline-light btn-sm p-2" id="subscribe-button">
        <span className="text-sm">
        <FontAwesomeIcon icon={faBell} className="mr-1" />Subscribe to updates
        </span>
      </button>
      <SubscriptionPopover target="subscribe-button" privacyPolicyUrl={config.companyTosUrl || 'https://app.firehydrant.io/legal/privacy'} />
    </>
  );
};

export default SubscriptionWidget;