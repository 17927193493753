import React, { useState } from "react";
import { useSelector } from "react-redux";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import PropTypes from "prop-types";
import { SubscribeSuccessIcon, SubscribeIcon } from "../Icon";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRss } from "@fortawesome/free-solid-svg-icons";

import { SubscriptionTOS } from "./SubscriptionTOS";
import { SubscriptionForm } from "./SubscriptionForm";
import { CUSTOMER_RSS_URL } from "../../../constants";

const propsForTab = (thisTab, activeTab, activateTab) => {
  return {
    className: activeTab === thisTab ? "active" : "",
    onClick: () => {
      activateTab(thisTab);
    },
  };
};

export const SubscriptionPopover = ({
  privacyPolicyUrl = "#tos",
  rssUrl = CUSTOMER_RSS_URL,
  target,
}) => {
  const [activeTab, setActiveTab] = useState("email");
  const [popoverOpen, setPopoverOpen] = useState(false);
  const subscribeSuccess = useSelector(
    (state) => state.api.subscriptionRequestSuccess
  );

  const togglePopover = () => setPopoverOpen(!popoverOpen);

  const renderSubscriptionFields = () => (
    <>
      <div className="row">
        <div className="col-2 mt-1">
          <SubscribeIcon />
        </div>
        <div className="col-10 ml--2">
          <h3>Stay updated on incidents</h3>
          <h3 className="mt--2">and upcoming maintenances.</h3>
        </div>
      </div>
      <hr className="my-2" />
      <SubscriptionForm />
      {/* <h4 className="hr-sect mt--3">&nbsp;&nbsp;Or&nbsp;&nbsp;</h4> */}
      <a target="_blank" href={rssUrl}>
        <button type="button" className="btn btn-block btn-outline-dark mt-1">
          <span {...propsForTab("rss", activeTab, setActiveTab)}>
            <FontAwesomeIcon icon={faRss} className="mr-2" />
            Subscribe with RSS
          </span>
        </button>
      </a>
      <SubscriptionTOS privacyPolicyUrl={privacyPolicyUrl} />
    </>
  );

  const renderSuccessMessage = () => (
    <>
      <div className="row text-right">
        <div className="col-3" />
        <div className="col-8">
          <div className="row">
            <SubscribeSuccessIcon className="ml-4" />
            <h3 className="mt-2 ml-2">Success!</h3>
          </div>
        </div>
        <div className="col-1" />
      </div>
      <hr className="my-3" />
      <div className="text-center">You will receive an email shortly</div>
      <div className="text-center">confirming your request.</div>
      <button
        type="button"
        onClick={togglePopover}
        className="btn btn-block btn-dark mt-4"
      >
        Close
      </button>
    </>
  );

  return (
    <UncontrolledPopover
      trigger="legacy"
      placement="bottom-end"
      hideArrow={true}
      isOpen={popoverOpen}
      target={target}
      toggle={togglePopover}
      modifiers={{ offset: { offset: "3px, 15px" } }}
      container={document.getElementById("nunc-v1-root")}
    >
      <PopoverBody className={"w-100 p-3 z-[50]"}>
        {!subscribeSuccess && renderSubscriptionFields()}
        {subscribeSuccess && renderSuccessMessage()}
      </PopoverBody>
    </UncontrolledPopover>
  );
};

SubscriptionPopover.propTypes = {
  privacyPolicyUrl: PropTypes.string,
  rssUrl: PropTypes.string,
  target: PropTypes.string,
};

export default SubscriptionPopover;
