import { LOAD_STATUS_PAGE_DATA_SUCCESS } from "../actions/actions";
import moment from "moment-timezone";

import {
  nuncConditionForCustomerCondition,
  DefaultCustomerCondition,
} from "../helpers/conditionHelpers";

const initialState = {
  activeIncidents: [],
  activeMaintenances: [],
  allIncidents: [],
  componentConditions: [],
  conditionMap: {},
  config: {},
  incidentMap: {},
  incidentsByDay: [],
  lastModifiedTime: "Mon, 10 Dec 2018 00:00:00 GMT",
  maintenanceMap: {},
  statusPageData: { incidents: [], initial: true },
  statusPageDataPending: true,
  upcomingMaintenances: [],
};

const isIncidentResolved = (incident) => !!incident.timestamps.resolved;
const hasStarted = (maintenance) => {
  return moment().isAfter(moment(maintenance.startsAt));
};
const isWithin24Hours = (maintenance) => {
  return (
    moment().diff(moment(maintenance.startsAt), "h") > -169 &&
    moment().diff(moment(maintenance.startsAt), "h") < 1 &&
    !hasStarted(maintenance)
  );
};
const mergeConditionsForActiveIncident = (incident, componentMap) => {
  if (isIncidentResolved(incident) || !incident.componentConditions) {
    return componentMap; // If the incident is not actice (is resolved) then we don't want to include the component conditions from this incident or the field is missing
  }

  const newMap = Object.assign({}, componentMap);
  const componentConditions = incident.componentConditions;
  const mapFromIncident = Object.keys(componentConditions).reduce((m, k) => {
    m[k] = { name: k, customerCondition: componentConditions[k] };
    return m;
  }, {});
  return Object.assign(newMap, mapFromIncident);
};

// Adds an incident to a map of dates organized by { yyyy -> { mm -> { dd -> [...]}}}
const addIncidentToMap = (incident, incidentMap) => {
  let incidentStartedAt = moment(incident.timestamps.started);
  let year = incidentStartedAt.year();
  let month = incidentStartedAt.month(); // NB: Jan==0
  let day = incidentStartedAt.date();
  incidentMap[year] = incidentMap[year] || {};
  let yearMap = incidentMap[year];
  yearMap[month] = yearMap[month] || {};
  let monthMap = yearMap[month];
  monthMap[day] = monthMap[day] || [];
  let incidentsForDay = monthMap[day];
  incidentsForDay.push(incident);
};

// Adds an incident to a map of dates organized by { yyyy -> { mm -> { dd -> [...]}}}
const addMaintenanceToMap = (incident, incidentMap) => {
  let incidentStartedAt = moment(incident.startsAt);
  let year = incidentStartedAt.year();
  let month = incidentStartedAt.month(); // NB: Jan==0
  let day = incidentStartedAt.date();
  incidentMap[year] = incidentMap[year] || {};
  let yearMap = incidentMap[year];
  yearMap[month] = yearMap[month] || {};
  let monthMap = yearMap[month];
  monthMap[day] = monthMap[day] || [];
  let incidentsForDay = monthMap[day];
  incidentsForDay.push(incident);
};

const mapCustomerConditions = (
  componentConditions = [],
  conditionsMap = {}
) => {
  for (let c of componentConditions) {
    if (!c.customerCondition) {
      c.customerCondition = DefaultCustomerCondition;
    }
    c.nuncCondition = nuncConditionForCustomerCondition(
      c.customerCondition,
      conditionsMap
    );
  }

  return componentConditions;
};

const processIncidents = ({ incidents = [], components = [], conditions }) => {
  let componentMap = components.reduce((m, item) => {
    m[item.name] = { name: item.name };
    return m;
  }, {}); // initialize the map

  const incidentMap = {};
  const activeIncidents = [];
  incidents.forEach((item) => {
    item.timeline = item.timeline ? item.timeline : [];
    addIncidentToMap(item, incidentMap);
    if (!isIncidentResolved(item)) {
      activeIncidents.push(item);
    }
    componentMap = mergeConditionsForActiveIncident(item, componentMap);
  });

  const componentConditions = mapCustomerConditions(
    Object.values(componentMap),
    conditions
  );
  return {
    incidentMap: incidentMap,
    componentConditions: componentConditions,
    activeIncidents: activeIncidents,
    allIncidents: incidents,
  };
};

const processMaintenances = ({ scheduledMaintenances = [] }) => {
  const maintenanceMap = {};
  const upcomingMaintenances = [];
  const activeMaintenances = [];
  if (scheduledMaintenances) {
    scheduledMaintenances.forEach((item) => {
      item.timeline = item.timeline ? item.timeline : [];
      addMaintenanceToMap(item, maintenanceMap);
      if (hasStarted(item)) {
        activeMaintenances.push(item);
      }
      if (isWithin24Hours(item) && !hasStarted(item)) {
        upcomingMaintenances.push(item);
      }
    });
  }

  return { maintenanceMap, upcomingMaintenances, activeMaintenances };
};

const processConfig = ({ config = {} }) => {
  const defaultConfig = {
    companyName: "",
    companyWebsite: "",
    companyTosUrl: "",
    recaptchaKey: "",
    greetingTitle: "",
    greetingBody: "",
    operationalMessage: "",
  };

  return Object.assign({}, defaultConfig, config);
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_STATUS_PAGE_DATA_SUCCESS: {
      const {
        incidentMap,
        componentConditions,
        activeIncidents,
        allIncidents,
      } = processIncidents(action.data);
      const { maintenanceMap, upcomingMaintenances, activeMaintenances } =
        processMaintenances(action.data);
      const updateTime = moment.tz("GMT").format("ddd, DD MMM YYYY HH:mm:ss z");
      let newState = {
        ...state,
        activeIncidents: activeIncidents,
        activeMaintenances: activeMaintenances,
        allIncidents: allIncidents,
        componentConditions: componentConditions,
        conditionMap: action.data.conditions || {},
        config: processConfig(action.data),
        incidentMap: incidentMap,
        lastModifiedTime: updateTime,
        maintenanceMap: maintenanceMap,
        statusPageData: action.data,
        statusPageDataPending: false,
        upcomingMaintenances: upcomingMaintenances,
      };
      delete newState.statusPageDataLoadError;
      return newState;
    }
    default:
      return state;
  }
}
