import { Link } from "react-router-dom";
import React from "react";

const IncidentHoverWrapper = ({
  children,
  incidentId,
  className = "",
  monthYear,
}) => {
  if (incidentId) {
    return (
      <Link
        className={"w-full rounded ${className}"}
        to={`/incidents/${incidentId}?month-year=${JSON.stringify(monthYear)}`}
      >
        {children}
      </Link>
    );
  }
  return children;
};

export default IncidentHoverWrapper;
