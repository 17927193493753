import { CustomFieldSection, IncidentFieldTypes } from "./IncidentFieldTypes";

import React from "react";

const IncidentAdditionalDetails = ({
  incident,
  exposedFields,
  allIncidents,
}) => (
  <div className="flex flex-col mb-16 border rounded border-fh-gray-6 bg-white dark:bg-fh-gray-dark-3 dark:border-fh-gray-dark-6 p-6">
    <h3 className="text-xl mb-6">Additional details</h3>
    <div className="flex flex-col w-full gap-4">
      {exposedFields?.map((field, index) => {
        if (field.indexOf("custom_field/") === 0) {
          return (
            <div className="flex flex-col" key={field}>
              <CustomFieldSection
                key={field}
                incident={incident}
                customFieldId={field.split("/")[1]}
              />
            </div>
          );
        }
        const Component = IncidentFieldTypes?.[field];
        if (Component)
          return (
            <Component
              key={field}
              incident={incident}
              exposedFields={exposedFields}
              index={index}
              allIncidents={allIncidents}
            />
          );
      })}
    </div>
  </div>
);

export default IncidentAdditionalDetails;
