import React from 'react';
import PropTypes from 'prop-types';
import { IncidentEvent, milestoneCopy, MilestoneUpdatePBType, BulkEventPBType } from './IncidentEvent';

// The milestoneCopy object has the milestones 'acknowledged', 'first_action', and 'started' all mapping to 'Investigating'
// The functions meetsTimelineDeleteByMilestoneUpdateCriteria and meetsTimelineDeleteByBulkUpdateCriteria functions below evaluates whether timeline event contains milestones where the milestone copy for the original and current milestones are the same, except when the currentMilestone is 'started'
// Milestone updates are made through MilestoneUpdatePBType and BulkEventPBType, so there are two functions that filter the timeline for milestone updates with redundant milestone copy
// (cleanUpTimelineByMilestoneUpdatePBType and cleanUpTimelineByBulkUpdatePBType)
// i.e., This is to avoid milestone updates in the UI that say "Transitioned from Investigating to Investigating"

// milestone updates
export const meetsTimelineDeleteByMilestoneUpdateCriteria = (item) => (milestoneCopy[item.details.currentMilestone] === milestoneCopy[item.details.originalMilestone]) && (item.details.currentMilestone !== 'started');

export const cleanUpTimelineByMilestoneUpdatePBType = (timeline) => {
  if (!timeline) return [];
  let result = timeline.filter(timelineEvent => !((timelineEvent.details['@type'] === MilestoneUpdatePBType) && meetsTimelineDeleteByMilestoneUpdateCriteria(timelineEvent)));
  return result;
};

// bulk updates
const meetsTimelineDeleteByBulkUpdateCriteria = (item) => {
  return (milestoneCopy[item.details.milestoneUpdate.currentMilestone] === milestoneCopy[item.details.milestoneUpdate.originalMilestone]) && !(item.details.milestoneUpdate.currentMilestone === 'started');
}

export const cleanUpTimelineByBulkUpdatePBType = (timeline) => {
  if (!timeline) return [];
  for (const timelineEvent in timeline) {
    if (timeline[timelineEvent].details['@type'] === BulkEventPBType) {
      const keysCount = Object.keys(timeline[timelineEvent].details);
      const keysCountExcludingType = keysCount.filter(key => key !== '@type');
      if (keysCountExcludingType.includes('milestoneUpdate')) {
          if (meetsTimelineDeleteByBulkUpdateCriteria(timeline[timelineEvent])) {
          if (keysCountExcludingType.length === 1) {
            delete timeline[timelineEvent];
          } else if (keysCountExcludingType.length > 1) {
            delete timeline[timelineEvent].details.milestoneUpdate;
          }
        }
      }
    }
  }
  return timeline.filter(event=> !!event);
};

export const cleanUpTimelineAllMilestoneUpdates = (timeline) => {
  return cleanUpTimelineByBulkUpdatePBType(cleanUpTimelineByMilestoneUpdatePBType(timeline));
};

export const DefaultTimelineFilter = (timeline) => {
  return cleanUpTimelineAllMilestoneUpdates(timeline);
};

export const FirstAndLastEventFilter = (timeline) => {
  const minimalTimeline = cleanUpTimelineAllMilestoneUpdates(timeline);
  const length = minimalTimeline.length;
  let result = [];
  if (length > 1) {
    result.push(minimalTimeline[0])
    result.push(minimalTimeline[length - 1])
  } else if (length === 1) {
    result.push(minimalTimeline[0]);
  }
return result;
};

export const LastEventFilter = (timeline) => {
  if (!timeline) return [];
  const minimalTimeline = cleanUpTimelineAllMilestoneUpdates(timeline);
  const length = minimalTimeline.length;
  let result = [];
  if (length > 0) {
    result.push(minimalTimeline[0]);
  }
  return result;
}


export const IncidentTimeline = ({ timeline, timelineFilter = DefaultTimelineFilter, EventType = IncidentEvent }) => {
  return timelineFilter(timeline).map(event => <EventType event={event} key={event.time} showTimestamp={timelineFilter !== LastEventFilter} />);
};

IncidentTimeline.propTypes = {
  timeline: PropTypes.array,
  timelineFilter: PropTypes.func,
};

export default IncidentTimeline;