import React from "react";
import Helmet from "react-helmet";
import ComponentHealthSection from "../components/ComponentHealthSection";
import MonthlyIncidentsSection from "../components/MonthlyIncidentsSection";
import { usePayloadContext } from "components/usePayloadContext";
import ActiveIncidents from "../components/ActiveIncidents";
import Greeting from "../components/Greeting";

const HomePage = () => {
  const all = usePayloadContext();
  const { components, componentGroups, config } = all;
  return (
    <>
      <Helmet>
        <title>{config?.companyName} Status</title>
      </Helmet>
      <Greeting />
      <ActiveIncidents />
      <ComponentHealthSection
        components={components}
        componentGroups={componentGroups}
      />
      <MonthlyIncidentsSection />
    </>
  );
};

export default HomePage;
