import { milestoneCopy } from "v2/constants/milestones";
import { BulkEventPBType, MilestoneUpdatePBType } from "v2/constants/events";

// I copied this file and modiefied it to just return events

// The milestoneCopy object has the milestones 'acknowledged', 'first_action', and 'started' all mapping to 'Investigating'
// The functions meetsTimelineDeleteByMilestoneUpdateCriteria and meetsTimelineDeleteByBulkUpdateCriteria functions below evaluates whether timeline event contains milestones where the milestone copy for the original and current milestones are the same, except when the currentMilestone is 'started'
// Milestone updates are made through MilestoneUpdatePBType and BulkEventPBType, so there are two functions that filter the timeline for milestone updates with redundant milestone copy
// (cleanUpTimelineByMilestoneUpdatePBType and cleanUpTimelineByBulkUpdatePBType)
// i.e., This is to avoid milestone updates in the UI that say "Transitioned from Investigating to Investigating"

// milestone updates
export const meetsTimelineDeleteByMilestoneUpdateCriteria = (item) =>
  milestoneCopy[item.details.currentMilestone] ===
    milestoneCopy[item.details.originalMilestone] &&
  item.details.currentMilestone !== "started";

export const cleanUpTimelineByMilestoneUpdatePBType = (timeline) => {
  if (!timeline) return [];
  let result = timeline?.filter(
    (timelineEvent) =>
      !(
        timelineEvent.details["@type"] === MilestoneUpdatePBType &&
        meetsTimelineDeleteByMilestoneUpdateCriteria(timelineEvent)
      )
  );
  return result;
};

// bulk updates
const meetsTimelineDeleteByBulkUpdateCriteria = (item) => {
  return (
    milestoneCopy[item.details.milestoneUpdate.currentMilestone] ===
      milestoneCopy[item.details.milestoneUpdate.originalMilestone] &&
    !(item.details.milestoneUpdate.currentMilestone === "started")
  );
};

export const cleanUpTimelineByBulkUpdatePBType = (originalTimeline) => {
  if (!originalTimeline) return [];
  const timeline = JSON.parse(JSON.stringify(originalTimeline));
  for (const timelineEvent in timeline) {
    if (timeline[timelineEvent].details["@type"] === BulkEventPBType) {
      const keysCount = Object.keys(timeline[timelineEvent].details);
      const keysCountExcludingType = keysCount?.filter(
        (key) => key !== "@type"
      );
      if (keysCountExcludingType.includes("milestoneUpdate")) {
        if (meetsTimelineDeleteByBulkUpdateCriteria(timeline[timelineEvent])) {
          if (keysCountExcludingType.length === 1) {
            delete timeline[timelineEvent];
          } else if (keysCountExcludingType.length > 1) {
            delete timeline[timelineEvent].details.milestoneUpdate;
          }
        }
      }
    }
  }
  return timeline?.filter((event) => !!event);
};

export const cleanUpTimelineAllMilestoneUpdates = (timeline = []) => {
  return cleanUpTimelineByBulkUpdatePBType(
    cleanUpTimelineByMilestoneUpdatePBType(timeline)
  );
};

export const LastEventFilter = (timeline) => {
  if (!timeline) return [];
  const minimalTimeline = cleanUpTimelineAllMilestoneUpdates(timeline);
  const length = minimalTimeline.length;
  let result = [];
  if (length > 0) {
    result.push(minimalTimeline[0]);
  }
  return result;
};

export const getLatestMilestone = (timeline = []) => {
  const latestMilestone = timeline?.find((update) => {
    return update?.details?.milestoneUpdate?.currentMilestone;
  });
  return latestMilestone?.details?.milestoneUpdate?.currentMilestone;
};

export const getLatestMilestoneFromTimestamps = (milestones = {}) => {
  if (milestones.resolved) {
    return "resolved";
  }

  let latestMilestone = null;
  for (const milestone in milestones) {
    if (
      !latestMilestone ||
      milestones[milestone] > milestones[latestMilestone]
    ) {
      latestMilestone = milestone;
    }
  }

  return latestMilestone;
};

export const getFilteredTimelineEvents = (timeline) => {
  return cleanUpTimelineAllMilestoneUpdates(timeline);
};

export const getNoteFromEvent = (event) => {
  return event?.details?.note?.note || event?.details?.note;
};

export const getLatestNoteEvent = (timeline = []) => {
  const noteEvent = timeline?.find((update) => {
    return update?.details?.note;
  });
  return noteEvent;
};
