import { emailSubscriptionRequest, emailSubscriptionSuccess, emailSubscriptionError, setGlobalAlert } from './actions';
import { emailUnsubscribeRequest, emailUnsubscribeSuccess } from './actions';
import { EMAIL_SUBSCRIPTION_URL } from '../constants';

const fakeRequest = false;
const SubscriptionApiUrl = EMAIL_SUBSCRIPTION_URL;
const doRequest = (data) => {

  let promise;
  if (fakeRequest) {
    promise = Promise.resolve({ message: "all good" });
  } else {
    const submission = {
      email: data.Email,
      recaptcha_token: data.recaptchaToken,
    };
    promise = fetch(SubscriptionApiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(submission)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Server responded with ${response.status}`); // TODO: Make this a bit nicer
        }

        return response.json();
      });
  }

  return promise;
};

export const submitSubscription = (data) => {
  const shouldRequest = (apiState) => (!apiState.subscriptionPending);

  return (dispatch, getState) => {
    if (!shouldRequest(getState().api)) { return; }

    dispatch(emailSubscriptionRequest(data));

    return doRequest(data)
      .then(response => {
        dispatch(emailSubscriptionSuccess(response));
      })
      .catch(error => {
        dispatch(emailSubscriptionError(error));
      });

  };
};

export const submitUnsubscribe = (data) => {
  return (dispatch, getState) => {
    dispatch(emailUnsubscribeRequest(data));

    fetch(`${SubscriptionApiUrl}/${data}`, { method: 'DELETE' })
      .then(response => response.json())
      .then(json => {
        dispatch(emailUnsubscribeSuccess(json));
        dispatch(setGlobalAlert({ level: "info", message: json.response }));
      })
  };
};