import React from "react";
import FHLogo from "../../images/fh-logo-with-text.png";

export const Footer = () => (
  <footer className="row align-items-center mb-2 max-w-lg">
    <div className="col-6" />
    <div className="col-lg-6 col-sm-12 text-right">
      <a href="https://firehydrant.io" target="_blank" className="text-muted">
        <small>
          <strong>Powered by</strong>
        </small>
        <img
          src={FHLogo}
          alt="FireHydrant logo"
          className="ml--1 h-[30px] inline-block"
        />
      </a>
    </div>
  </footer>
);

export default Footer;
