import moment from "moment";

export const DayUnit = "day";
export const WeekUnit = "week";
export const MonthUnit = "month";
export const DefaultSpanUnit = MonthUnit;
export const DefaultSpanUnitsPerPage = 3;
export const DefaultSpanUnitIncrement = 1;

// Creates a javascript generator that will generate a sequence of timespans as directed by the "steps" and "stepSize" with
// all incidents occuring in that timespan. NOTE: THis only goes in descending order right now. Bonus points if you can make this work in both directions.
export const createHistoryIterator = ({
  incidents,
  from,
  until,
  stepSize = [DefaultSpanUnitIncrement, DayUnit],
}) => {
  return {
    *[Symbol.iterator]() {
      const [stepIncrement, stepUnit] = stepSize;

      const start = moment(until);
      const end = moment(from);

      const advance = (m) => moment(m).subtract(stepIncrement, stepUnit); //(isAscending ? (m => moment(m).add(stepIncrement, stepUnit)) : (m => moment(m).subtract(stepIncrement, stepUnit)));
      const keepGoing = (m, limit) => m.isSameOrAfter(limit); //(isAscending ? ((m, limit) => m.isSameOrBefore(limit)) : ((m, limit) => m.isSameOrAfter(limit)));

      let current = moment(start);
      const limit = moment(end);

      while (keepGoing(current, limit)) {
        let next = advance(current);

        // NB: How would I remove the reference to current in the filter function?
        // eslint-disable-next-line
        let inRange = incidents
          .filter((i) => moment(i.timestamps.started).isBetween(next, current))
          .sort((a, b) => {
            const aTime = moment(a.timestamps.started);
            const bTime = moment(b.timestamps.started);
            if (aTime.isBefore(bTime)) {
              return -1;
            } else if (bTime.isBefore(aTime)) {
              return 1;
            } else {
              return 0;
            }
          });

        let result = { from: moment(current), until: next, incidents: inRange };
        let reset = yield result;
        if (reset) {
          current = moment(start);
        } else {
          current = moment(next); // Yes, it's mutable and I could just update in place but that's yucky
        }
      }
    },

    // return generator;
  };
};

export default createHistoryIterator;
