import React from 'react'
import { Card, CardHeader, CardBody } from 'reactstrap'
import { OperationalIcon, MaintenanceIcon, DegradedIcon, OutageIcon } from '../shared/Icon'

export const StatusKey = () => {
  // TODO(resputin): This can and should be refactored
  return (
    <Card className="no-shadow sticky">
      <CardHeader className="bg-dark py-2">
        <div className="row no-gutters">
          <div className="col-12">
            <h3 className="text-white mb-0">Status key</h3>
          </div>
        </div>
      </CardHeader>
      <CardBody className="py-1 border border-top-0 rounded-bottom">
        <div className="row no-gutters">
          <div className="col-12 px-0 border-bottom d-flex py-2">
            <OperationalIcon size="sm" />
            <p className="text-sm pl-2 mb-0">
              No Issues
            </p>
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-12 px-0 border-bottom d-flex py-2">
            <MaintenanceIcon size="sm" />
            <p className="text-sm pl-2 mb-0">
              Maintenance
            </p>
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-12 px-0 border-bottom d-flex py-2">
            <DegradedIcon size="sm" />
            <p className="text-sm pl-2 mb-0">
              Incident
            </p>
          </div>
        </div>
        <div className="row no-gutters">
          <div className="col-12 px-0 d-flex py-2">
            <OutageIcon size="sm" />
            <p className="text-sm pl-2 mb-0">
              Outage
            </p>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default StatusKey
