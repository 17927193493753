import React from "react";
import { StatusIcons } from "../Icons";
import { statusColors } from "v2/constants/colors";
import Tag from "v2/components/common/Tag";
import { usePayloadContext } from "components/usePayloadContext";

const IncidentHeader = ({ bannerType, title, milestone, incident }) => {
  const { config } = usePayloadContext();
  const StatusIcon = StatusIcons[bannerType];
  const bannerColor = statusColors[bannerType];
  const showSeverity = config?.exposedFields?.includes("default/severity");
  const showPriority = config?.exposedFields?.includes("default/priority");
  const severity = incident?.severitySlug;
  const priority = incident?.priority;
  const displayMilestone = milestone.startsWith("postmortem_") ?
    milestone.replace("postmortem_", "retrospective ") :
    milestone;
  return (
    <div
      className={`flex w-full border bg-white border-fh-gray-6 dark:bg-fh-gray-dark-3 dark:border-fh-gray-dark-6 dark:opacity-90 rounded mb-4 overflow-hidden`}
    >
      <div
        className={`bg-fh-${bannerColor} p-1 rounded-l relative mt-[-1px]`}
      />
      <div className="flex w-full py-6 px-4 gap-4 item-center">
        <StatusIcon className={`w-[32px] h-[32px] text-fh-${bannerColor}`} />
        <div className="flex flex-col gap-3 font-medium">
          <h3 className="text-xl leading-1">{title}</h3>
          <div className="my-2 flex">
            {milestone && (
              <Tag size="xs" className="capitalize w-fit" variant="milestone">
                {displayMilestone}
              </Tag>
            )}
            {showSeverity && (
              <Tag size="xs" variant="lighter" className="capitalize w-fit">
                {severity}
              </Tag>
            )}
            {showPriority && (
              <Tag size="xs" variant="lighter" className="capitalize w-fit">
                {priority}
              </Tag>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncidentHeader;
