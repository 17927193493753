import React from 'react';

const MaintenanceAlert = ({ maintenanceCount }) => {
  const renderMaintenanceText = () => {
    if (maintenanceCount === 1) {
      return 'Maintenance in progress';
    } else if (maintenanceCount > 1) {
      return `${maintenanceCount} maintenances in progress`;
    }
  };

  return (
    <span className="text-white badge badge-pill badge-maintenance mr-3 py-2 mb-3">
      {renderMaintenanceText()}
    </span>
  )
}

export default MaintenanceAlert;
