import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  DefaultSpanUnit,
  DefaultSpanUnitsPerPage,
} from "../../helpers/incidentHistoryIterator";

export const HistoryNavigator = ({
  start,
  end,
  stepSize = [DefaultSpanUnitsPerPage, DefaultSpanUnit],
  format = "MMM YYYY",
}) => {
  const [stepIncrement, stepUnit] = stepSize;
  const navigate = useNavigate();
  const forward = () => {
    const nextStart = moment(end).add(1, stepUnit).startOf(DefaultSpanUnit);
    const nextEnd = moment(nextStart)
      .add(stepIncrement - 1, stepUnit)
      .endOf(DefaultSpanUnit);
    navigate(
      `?start=${nextStart.format("YYYY-MM-DD")}&end=${nextEnd.format(
        "YYYY-MM-DD"
      )}`
    );
  };

  const now = moment().format("YYYY-MM");
  const disableNavigator = now <= end.format("YYYY-MM"); // prevent from tapping into future months

  const back = () => {
    const nextEnd = moment(start).subtract(1, stepUnit).endOf(DefaultSpanUnit);
    const nextStart = moment(nextEnd)
      .subtract(stepIncrement - 1, stepUnit)
      .startOf(DefaultSpanUnit);
    navigate(
      `?start=${nextStart.format("YYYY-MM-DD")}&end=${nextEnd.format(
        "YYYY-MM-DD"
      )}`
    );
  };

  return (
    <div className="history-navigator text-nowrap">
      <button className="btn-sm btn-outline-dark" onClick={back}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <span className="ml-2 mr-2">
        {start.format(format)} to {end.format(format)}
      </span>
      <button
        className={`${
          disableNavigator ? "btn-outline-disable" : "btn-outline-dark"
        } btn-sm`}
        disabled={disableNavigator}
        onClick={forward}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
};

HistoryNavigator.propTypes = {
  start: PropTypes.shape(),
  end: PropTypes.shape(),
  stepSize: PropTypes.array,
  format: PropTypes.string,
};

export default HistoryNavigator;
