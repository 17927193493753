import React from "react";
import {
  OperationalIcon,
  DegradedIcon,
  OutageIcon,
  MaintenanceIcon,
} from "../Icons";

const ConditionLegend = () => (
  <div className="mr-2 flex sm:flex-row flex-col items-end sm:items-center text-[14px]">
    <div className="flex flex-row items-center">
      <OperationalIcon className="w-[12px] h-[12px] mr-1" />
      <p>Operational</p>
    </div>
    <div className="flex flex-row items-center ml-2">
      <DegradedIcon className="w-[12px] h-[12px] mr-1" />
      <p>Degraded</p>
    </div>
    <div className="flex flex-row items-center ml-2">
      <OutageIcon className="w-[12px] h-[12px] mr-1" />
      <p>Outage</p>
    </div>
    <div className="flex flex-row items-center ml-2">
      <MaintenanceIcon className="w-[12px] h-[12px] mr-1" />
      <p>Maintenance</p>
    </div>
  </div>
);

export default ConditionLegend;
