import { useEffect, useRef } from 'react';

export const usePeriodically = (callback, delay) => {
  const savedCallback = useRef(); // We use a ref to store our interval callbacks across renders

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]); // If the callback changes we will rerender.

  // Set up the interval.
  useEffect(() => {
    const onTimeout = () => {
      // When the timeout happens, call the saved callback
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(onTimeout, delay);
      return () => clearInterval(id); // Return a function to cleanup this interval
    }
  }, [delay]);
};

export default usePeriodically;