import React, { useEffect } from "react";
import Header from "../components/Header";
import { useTitle } from "../helpers/useTitle";

import { useParams } from "react-router-dom";
import { submitUnsubscribe } from "../actions/unsubscribe";
import Helmet from "react-helmet";
import { usePayloadContext } from "components/usePayloadContext";

export default function UnsubscribePage() {
  const { token } = useParams();
  const { config } = usePayloadContext();

  useTitle("Unsubscribe");

  useEffect(() => {
    if (token) {
      submitUnsubscribe(token);
    }
  }, [token]);

  return (
    <div>
      <Helmet>
        <title>{config?.companyName} Status</title>
      </Helmet>
      <div className="flex justify-center items-center p-12">
        <h1 className="text-4xl">Unsubscribed!</h1>
      </div>
    </div>
  );
}
