import React from 'react';
import { useSelector } from 'react-redux';
import { styleForCondition, conditionIcons } from '../../helpers/conditionHelpers';
import { Card, CardHeader, CardBody } from 'reactstrap';

export const ComponentConditionCollection = () => {
  const components = useSelector(state => state.statusPage.componentConditions);

  const renderComponentConditions = () => {
    return components.map((c, index) => {
      const icon = conditionIcons[c.nuncCondition]
      let border = 'border-bottom';
      if (index === components.length - 1) {
        border = '';
      }
      return (
        <div className="row mx-3" key={`component-${c.name}`}>
          <div className={`col-6 px-0 py-3 ${border}`}>
            <p className="mb-0">
              {c.name}
            </p>
            {/* TODO(resputin): Need to figure out this logic */}
            <span className="text-sm">{c.active ? 'Somethings not quite right' : ' '}</span>
          </div>
          <div className={`col-6 px-0 py-3 ${border} ml-auto`}>
            {icon({ className: 'ml-auto'})}
          </div>
        </div>
      )
    }

    )
  };

  return (
    <Card className="no-shadow">
      <CardHeader className="border-bottom-0">
        <div className="row">
          <div className="col-12">
            <h2 className="mb-0">Current status by service</h2>
          </div>
        </div>
      </CardHeader>
      <CardBody className="pt-0">
        <div className="border rounded">
          {renderComponentConditions()}
        </div>
      </CardBody>
    </Card>
  );
};

export default ComponentConditionCollection;