import React from "react";
import PropTypes from 'prop-types';
import moment from 'moment';
import { Card, CardBody, CardHeader } from 'reactstrap';

export const IncidentsForTimespan = ({ incidents, date, format = 'MMMM YYYY', Child, viewType = 'PAST_INCIDENTS' }) => {
  let elements = incidents.map((incident, i) => (
    <Child incident={incident} key={`history-incident-${incident.id}`} showBorderBottom={i < incidents.length - 1 }/>
  ));

  if (viewType === 'PAST_INCIDENTS') {
    return (
      <div className="incident-history-span-header py-1" key={`history-span-${date.format(format)}`}>
        {elements}
      </div>
    );
  } else if (viewType === 'INCIDENT_HISTORY') {
    return (
      <Card className="no-shadow">
        <CardHeader className="bg-dark py-1">
          <h2 className="text-white mb-0">{date.format(format)}</h2>
        </CardHeader>
        <CardBody className="border rounded-bottom border-top-0 p-3">
          {elements}
          {!elements.length && <div><em>No incidents occurred this month</em></div>}
        </CardBody>
      </Card>
    );
  }
};

IncidentsForTimespan.propTypes = {
  incidents: PropTypes.array,
  date: PropTypes.string,
  format: PropTypes.string,
  Child: PropTypes.node,
  viewType: PropTypes.string,
};

export default IncidentsForTimespan; 