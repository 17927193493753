import React from "react";
import { useSelector } from "react-redux";
import { useQueryParams } from "../../hooks/useQueryParams";

import moment from "moment";
import IncidentsForTimespan from "../shared/IncidentsForTimespan";
import createHistoryIterator from "../../helpers/incidentHistoryIterator";
import { HistoryNavigator } from "./HistoryNavigator";
import {
  MonthUnit,
  DefaultSpanUnitIncrement,
} from "../../helpers/incidentHistoryIterator";
import { incidentsSelector } from "../../helpers/stateSelectors";
import Helmet from "react-helmet";
import IncidentHistorySummary from "../shared/IncidentHistorySummary";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignal } from "@fortawesome/free-solid-svg-icons";
import { sortIncidentsByMostRecentUpdate } from "../../helpers/sortIncidents";

const MonthsToShow = 3;
const getStartAndEnd = ({
  query,
  spanUnit = MonthUnit,
  numUnits = MonthsToShow,
}) => {
  let end = query.get("end");
  let start = query.get("start");

  if (start && end) {
    start = moment(start).startOf(spanUnit);
    end = moment(end).endOf(spanUnit);
  } else if (start && !end) {
    start = moment(start).startOf(spanUnit);
    end = moment(start).add(numUnits - 1, spanUnit);
  } else if (!start && end) {
    end = moment(end).endOf(spanUnit);
    start = moment(end).subtract(numUnits, spanUnit);
  } else {
    end = moment().endOf(spanUnit);
    start = moment(end).subtract(numUnits - 1, spanUnit);
  }
  return [start, end];
};

export const HistoryView = () => {
  const incidents = useSelector(incidentsSelector);
  const config = useSelector((state) => state.statusPage.config);
  const query = useQueryParams();
  const [start, end] = getStartAndEnd({ query: query });
  const incidentsExcludingMaintenance = incidents.filter(
    (incident) => incident.severitySlug !== "MAINTENANCE"
  );

  const iter = createHistoryIterator({
    incidents: incidentsExcludingMaintenance,
    from: start,
    until: end,
    stepSize: [DefaultSpanUnitIncrement, MonthUnit],
  });

  const elements = Array.from(iter).map((timespan) => (
    <IncidentsForTimespan
      incidents={sortIncidentsByMostRecentUpdate(timespan.incidents)}
      date={timespan.from}
      format="MMMM YYYY"
      key={timespan.from.format("MMMM YYYY")}
      Child={IncidentHistorySummary}
      viewType={"INCIDENT_HISTORY"}
    />
  ));
  return (
    <>
      {Object.keys(config).length > 0 && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{config.companyName} Status History</title>
        </Helmet>
      )}
      <div className="history-container">
        <div className="row">
          <div className="col-12 mb-4">
            <Link to="/" className="text-dark">
              <FontAwesomeIcon icon={faSignal} className="pr-1" size="lg" />
              View status page
            </Link>
          </div>
        </div>
        <div className="row align-items-center mb-3 border-bottom mx-1 pb-2 justify-content-between">
          <div className="px-0 pb-2">
            <h1 className="mb-0">Incident history</h1>
          </div>
          <div className="col-6 text-right px-0">
            <HistoryNavigator start={start} end={end} />
          </div>
        </div>
        <div className="row">
          <div className="col-12">{elements}</div>
        </div>
      </div>
    </>
  );
};

export default HistoryView;
