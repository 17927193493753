import React from "react";
import { Link } from "react-router-dom";

const LinkButton = ({ to, children }) => {
  return (
    <Link to={to}>
      <button className="text-sm border dark:border-gray-8 px-3 py-1.5 font-medium rounded text:gray-light-12 dark:text-gray-dark-12 border-gray-light-8">
        {children}
      </button>
    </Link>
  );
};

export default LinkButton;
