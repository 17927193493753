import React from "react";
import nodeEmoji from "node-emoji";
import ReactMarkdown from "react-markdown";
import breaks from "remark-breaks";
import remarkGfm from "remark-gfm";

const Markdown = ({ source, ...props }) => {
  const filteredSource =
    source
      ?.replace(/:skin-tone-\d:/gi, "")
      ?.replace(/:\w+:/gi, (emoji) => nodeEmoji.get(emoji)) || "";
  return (
    <div className="nunc-markdown">
      <ReactMarkdown
        disallowedElements={[
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          "table",
          "tableHead",
          "tableBody",
          "tableRow",
          "tableCell",
        ]}
        remarkPlugins={[breaks, remarkGfm]}
        skipHtml
        unwrapDisallowed
        linkTarget="_blank"
      >
        {filteredSource}
      </ReactMarkdown>
    </div>
  );
};

export default Markdown;
