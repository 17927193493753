import {
  createStore, applyMiddleware, compose,
} from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './reducers/rootReducer';

const middlewares = [thunk];
let enhancers;

if (process.env.NODE_ENV === 'development') {
  enhancers = compose(
    applyMiddleware(...middlewares),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  );
} else {
  enhancers = compose(
    applyMiddleware(...middlewares),
  );
}

const store = createStore(rootReducer, {}, enhancers);

export default store;
