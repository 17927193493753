import React from "react";
import { Card, CardHeader, CardBody } from "reactstrap";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  styleForCustomerCondition,
  styleForCondition,
  DegradedCondition,
  getMostSevereComponent,
  conditionIcons,
  ScheduledMaintenanceCondition,
} from "../../helpers/conditionHelpers";
import IncidentSummary from "../shared/IncidentSummary";
import IncidentLink, { lastUpdate } from "../shared/IncidentLink";
import { OperationalIcon, MaintenanceIcon } from "../shared/Icon";
import { Link } from "react-router-dom";
import IncidentTimeline, { LastEventFilter } from "../shared/IncidentTimeline";
import { sortIncidentsByMostRecentUpdate } from "../../helpers/sortIncidents";
import { relativeIncidentUrl } from "../../helpers/urls";

const DefaultNuncConditionForActiveIncident = DegradedCondition;
const DefaultCustomerConditionForActiveIncident = "Notice";

const ActiveIncidentMarquee = ({ incident, conditionMap }) => {
  const component = getMostSevereComponent(
    incident.componentConditions,
    conditionMap
  ); // TODO: What component should we base the system status condition on?
  const conditionStyle = component
    ? styleForCustomerCondition(
        component.customerCondition,
        conditionMap,
        DefaultNuncConditionForActiveIncident
      )
    : styleForCondition(DefaultNuncConditionForActiveIncident);
  const customerCondition = component
    ? component.customerCondition
    : DefaultCustomerConditionForActiveIncident;
  const severity = component && component.customerCondition;

  return (
    <div className="row">
      <div className="col-2"></div>
      <div className="col-8">
        <Link to={relativeIncidentUrl(incident)} className="unstyled">
          <Card className="no-shadow">
            <CardHeader className="border-bottom-0">
              <div className="row">
                <div className="col-12">
                  <IncidentLink
                    incident={incident}
                    linkText={`${
                      customerCondition ? `${customerCondition}: ` : ""
                    }${incident.title}`}
                    className={`text-${conditionStyle} display-4`}
                  />
                </div>
              </div>
            </CardHeader>
            <CardBody className="border-top-0 pt-0">
              <IncidentSummary incident={incident} showTitle={false} />
            </CardBody>
          </Card>
        </Link>
      </div>
      <div className="col-2"></div>
    </div>
  );
};

export const SystemOperational = () => (
  <Card className="no-shadow">
    <CardHeader className="border-bottom-0">
      <div className="text-center mb-4">
        <OperationalIcon size="xl" gradient className="mx-auto" />
        <h1 className="mt-3">All systems operational</h1>
      </div>
    </CardHeader>
  </Card>
);

const IncidentCard = ({ incident }) => {
  const conditionMap = useSelector((state) => state.statusPage.conditionMap);
  const component = getMostSevereComponent(
    incident.componentConditions,
    conditionMap
  );

  let icon = conditionIcons[DegradedCondition];
  if (incident?.severitySlug === "MAINTENANCE") {
    icon = conditionIcons[ScheduledMaintenanceCondition];
  } else if (component) {
    icon =
      conditionIcons[
        conditionMap[component.customerCondition] || DegradedCondition
      ];
  }
  return (
    <div className="col-12 col-md-6 col-lg-4">
      <Link to={relativeIncidentUrl(incident)} className="unstyled">
        <Card className="no-shadow">
          <CardHeader className="bg-dark p-3">
            <div className="row text-white">
              <div className="col-9 pr-0">
                <span className="text-sm">
                  <span>
                    <strong className="text-white">Updated: </strong>
                  </span>
                  <span className="text-nowrap">{lastUpdate(incident)}</span>
                </span>
              </div>
              <div className="col-3 align-content-start">
                {icon({ className: "ml-auto" })}
              </div>
            </div>
            <h3 className="text-white mt-2">{incident.title}</h3>
          </CardHeader>
          <CardBody className="border rounded-bottom border-top-0 px-3 py-2">
            {incident.timeline && (
              <IncidentTimeline
                timeline={incident.timeline}
                timelineFilter={LastEventFilter}
              />
            )}
            {!incident.timeline.length && (
              <div className="my-1">
                <em>No additional details recorded at this time</em>
              </div>
            )}
          </CardBody>
        </Card>
      </Link>
    </div>
  );
};

const MaintenanceCard = ({ maintenance }) => {
  const renderMaintenanceSummary = () => (
    <div className="py-2 mb-0 mt-3 px-3 bg-shade text-dark lh-130">
      <p className="mb-0 d-inline">{maintenance.summary}</p>
    </div>
  );

  return (
    <div className="col-12 col-md-6 col-lg-4">
      <Card className="no-shadow">
        <CardHeader className="bg-dark pt-3 pb-1 px-3">
          <div className="row text-white">
            <div className="col-9 pr-0">
              <h3 className="text-white">{maintenance.name}</h3>
            </div>
            <div className="col-3 align-content-start">
              <MaintenanceIcon className="ml-auto" />
            </div>
          </div>
        </CardHeader>
        <CardBody className="border border-top-0 rounded-bottom p-3">
          <div className="mb-2">
            <strong>Start time: </strong>
            {moment(maintenance.startsAt).format("MMMM DD, YYYY [|] h:mma")}
          </div>
          <div className="my-2">
            <strong>End time: </strong>
            {moment(maintenance.endsAt).format("MMMM DD, YYYY [|] h:mma")}
          </div>
          {maintenance.summary && renderMaintenanceSummary()}
        </CardBody>
      </Card>
    </div>
  );
};

export const CurrentIncidentStatus = () => {
  const isInitialState = useSelector(
    (state) => state.statusPage.statusPageData.initial
  );
  let activeIncidents = useSelector(
    (state) => state.statusPage.activeIncidents
  );
  const conditionMap = useSelector((state) => state.statusPage.conditionMap);

  const sortedIncidents = sortIncidentsByMostRecentUpdate(activeIncidents);

  if (isInitialState) {
    return <></>;
  }

  if (activeIncidents.length === 0) {
    return <SystemOperational />;
  }

  if (activeIncidents.length === 1) {
    return activeIncidents.map((i) => (
      <ActiveIncidentMarquee
        incident={i}
        conditionMap={conditionMap}
        key={`active-incident-${i.id}`}
      />
    ));
  }

  return (
    <Card className="no-shadow">
      <CardHeader className="border-bottom-0">
        <div className="row">
          <div className="col-12">
            <h2 className="mb-0 border-bottom">
              {activeIncidents.length} incidents
            </h2>
          </div>
        </div>
      </CardHeader>
      <CardBody className="pt-0">
        <div className="row">
          {sortedIncidents.map((i) => (
            <IncidentCard
              incident={i}
              conditionMap={conditionMap}
              key={`active-incident-${i.id}`}
            />
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

export const CurrentMaintenanceStatus = () => {
  const activeMaintenances = useSelector(
    (state) => state.statusPage.activeMaintenances
  );
  if (activeMaintenances.length < 1) return <></>;
  return (
    <Card className="no-shadow">
      <CardHeader className="border-bottom-0">
        <div className="row">
          <div className="col-12">
            <h2 className="mb-0 border-bottom">
              {activeMaintenances.length} undergoing maintenance
              {activeMaintenances.length > 1 ? "s" : ""}
            </h2>
          </div>
        </div>
      </CardHeader>
      <CardBody className="pt-0">
        <div className="row">
          {activeMaintenances.map((i) => (
            <MaintenanceCard
              maintenance={i}
              key={`active-maintenance-${i.id}`}
            />
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

export const UpcomingMaintenanceStatus = () => {
  const upcomingMaintenances = useSelector(
    (state) => state.statusPage.upcomingMaintenances
  );
  if (upcomingMaintenances.length < 1) return <></>;
  return (
    <Card className="no-shadow">
      <CardHeader className="border-bottom-0">
        <div className="row">
          <div className="col-12">
            <h2 className="mb-0 border-bottom">
              {upcomingMaintenances.length} upcoming maintenance
              {upcomingMaintenances.length > 1 ? "s" : ""}
            </h2>
          </div>
        </div>
      </CardHeader>
      <CardBody className="pt-0">
        <div className="row">
          {upcomingMaintenances.map((i) => (
            <MaintenanceCard
              maintenance={i}
              key={`upcoming-maintenance-${i.id}`}
            />
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

export default CurrentIncidentStatus;
