import { combineReducers } from 'redux';
import statusPageDataReducer from './statusPageDataReducer';
import apiReducer from './apiReducer';
import applicationReducer from './applicationReducer';

const rootReducer = combineReducers({
  app: applicationReducer,
  statusPage: statusPageDataReducer,
  api: apiReducer,
});

export default rootReducer;