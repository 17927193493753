import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { submitUnsubscribe } from '../../actions/subscribeViaEmail';
import Helmet from 'react-helmet';

export const UnsubscribeView = () => {
  const { token } = useParams()
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(submitUnsubscribe(token));
  }, [token, dispatch]);

  return (<>

    <Helmet>
      <meta charSet="utf-8" />
      <title>Unsubscribe</title>
    </Helmet>
    <h1>Unsubscribe!</h1>
  </>);
};

export default UnsubscribeView;