import React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import Tooltip from "./Tooltip";

dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(timezone);

const Timestamp = ({ timestamp }) => {
  const dayjsDate = dayjs.utc(timestamp);
  const formattedDate = dayjsDate.format("MMM D, YYYY, h:mm A [UTC]");
  const localDate = dayjs(timestamp).local().format("MMM D, YYYY, h:mm A");

  return (
    <Tooltip
      tooltipContent={
        <div className="text-xs">
          <p className="mb-2">{formattedDate}</p>
          <p>
            {localDate} {dayjs(timestamp).local().format("z")}
          </p>
        </div>
      }
    >
      <span>
        {localDate} {dayjs(timestamp).local().format("z")}
      </span>
    </Tooltip>
  );
};

export default Timestamp;
