import React from 'react';
import { OperationalIcon, DegradedIcon, OutageIcon, MaintenanceIcon } from '../components/shared/Icon';
export const OperationalCondition = "OPERATIONAL";
export const DegradedCondition = "DEGRADED";
export const OfflineCondition = "OFFLINE";
export const ScheduledMaintenanceCondition = "SCHEDULED_MAINTENANCE";
export const UnknownCondition = "UNKNOWN";

const conditionStyles = {
  [OperationalCondition]: "success",
  [DegradedCondition]: "warning",
  [OfflineCondition]: "danger",
  [ScheduledMaintenanceCondition]: "info",
  [UnknownCondition]: "secondary"
};

const conditionOrdering = {
  [OperationalCondition]: 0,
  [UnknownCondition]: 1,
  [ScheduledMaintenanceCondition]: 2,
  [DegradedCondition]: 3,
  [OfflineCondition]: 4,
};

export const conditionIcons = {
  [OperationalCondition]: props => <OperationalIcon {...props} />,
  [ScheduledMaintenanceCondition]: props => <MaintenanceIcon {...props} />,
  [DegradedCondition]: props => <DegradedIcon {...props} />,
  [UnknownCondition]: props => <DegradedIcon {...props} />,
  [OfflineCondition]: props => <OutageIcon {...props} />,
};


export const DefaultCustomerCondition = "No Issues";
export const DefaultNuncCondition = OperationalCondition;

export const styleForCondition = (nuncCondition) => (conditionStyles[nuncCondition] || UnknownCondition);

export const nuncConditionForCustomerCondition = (customerCondition, conditionMap, defaultNuncCondition = DefaultNuncCondition) => {
  return conditionMap[customerCondition || DefaultCustomerCondition] || defaultNuncCondition;
};

export const styleForCustomerCondition = (customerCondition, conditionMap, defaultNuncCondition = DefaultNuncCondition) => conditionStyles[nuncConditionForCustomerCondition(customerCondition, conditionMap, defaultNuncCondition)];

export const componentConditionsToArray = (componentConditions) => {
  const ar = [];
  if (componentConditions) {
    for (let item of Object.keys(componentConditions)) {
      ar.push({name: item, customerCondition: componentConditions[item]});
    }
  }
  return ar;
};

export const sortComponentsByCondition = (componentConditions, conditionMap) => (
  componentConditionsToArray(componentConditions).sort((a, b) => (
    conditionOrdering[conditionMap[b.name] || UnknownCondition] - conditionOrdering[conditionMap[a.name] || UnknownCondition]
  ))
);

export const getMostSevereComponent = (componentConditions, conditionMap) => (sortComponentsByCondition(componentConditions, conditionMap)[0]);

export default styleForCondition;