import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IncidentDetails } from "./IncidentDetails";
import { getIncidentByIdSelector } from "../../helpers/stateSelectors";
import {
  getMostSevereComponent,
  conditionIcons,
  DegradedCondition,
} from "../../helpers/conditionHelpers";

const IncidentView = () => {
  const { incidentId } = useParams();
  const incident = useSelector(getIncidentByIdSelector(incidentId));
  const conditionMap = useSelector((state) => state.statusPage.conditionMap);
  const prettyConditionMap = Object.keys(conditionMap || {}).reduce(
    (obj, key) => {
      obj[key] =
        conditionMap[key][0] + conditionMap[key].slice(1).toLowerCase();
      return obj;
    },
    { maintenance: "Maintenance" }
  );

  let icon;
  let component;

  if (incident) {
    component = getMostSevereComponent(
      incident.componentConditions,
      conditionMap
    );
    icon = component
      ? conditionIcons[
          conditionMap[component.customerCondition] || DegradedCondition
        ]
      : conditionIcons[DegradedCondition];
  }

  if (incident) {
    return (
      <IncidentDetails
        incident={incident}
        icon={icon}
        condition={component}
        conditionMap={prettyConditionMap}
      />
    );
  } else {
    return <h1>Incident not found.</h1>;
  }
};

export default IncidentView;
