import React from "react";
import { usePayloadContext } from "components/usePayloadContext";

const Greeting = () => {
  const { config } = usePayloadContext();
  return (
    <>
      {config?.greetingTitle && (
        <h1 className="text-xl font-medium pb-2">{config?.greetingTitle}</h1>
      )}
      {config?.greetingBody && (
        <p className="text-md">{config?.greetingBody}</p>
      )}
    </>
  );
};

export default Greeting;
