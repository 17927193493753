import React from 'react';
import PropTypes from 'prop-types';

const GooglePrivacyPolicyUrl = "https://policies.google.com/privacy";
const GoogleTOSUrl = "https://policies.google.com/terms";

export const SubscriptionTOS = ({ privacyPolicyUrl }) => (
  <div className="mt-4">
    <div className="text-sm mb-2">View <a href={privacyPolicyUrl} target="_blank">FireHydrant Privacy Policy</a></div>
    <span className="text-sm">We use ReCAPTCHA. The Google <a href={GooglePrivacyPolicyUrl}>Privacy Policy</a> and <a href={GoogleTOSUrl}>Terms of Service</a> apply.</span>
  </div>
);

SubscriptionTOS.propTypes = {
  privacyPolicyUrl: PropTypes.string,
};

export default SubscriptionTOS;